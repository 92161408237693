import React from 'react';
import { useTranslation } from 'react-i18next';

import { DoneIllustrationIcon } from 'app/common/shared/icons/icons';
import { Text } from 'app/common/shared/text/text';
import { useAuthStore } from '../authentication/use-auth-store';

export const ThankYouScreen = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();

  return (
    <div className='flex items-center justify-center mt-16 flex-col text-center px-6'>
      <DoneIllustrationIcon className='mb-8' />
      <Text variant='h3ExtraBold'>
        <h3 className='m-0 mb-2'>{t('thank_you_onboarding')}</h3>
      </Text>
      <Text variant='h4SemiBold'>
        <h3 className='m-0 mb-8 max-w-[75%]'>{t('thank_you_onboarding_p1')}</h3>
      </Text>
      <Text variant='h5Regular'>
        <h3 className='m-0 mb-6'>{t('thank_you_onboarding_p2')}</h3>
      </Text>
      <Text variant='h5Regular'>
        <h3 className='m-0'>
          {t('thank_you_onboarding_p3')}
          <br />
          <Text variant='h5Regular'>
            <a
              href={`mailto:hello@getalfi.com?subject=Approval Status - ${user.fullName} - ${user.phoneCountryId}-${user.phoneNumber} - ${user.email}`}
              className='no-underline'
            >
              <span className='m-0 text-link'>
                {t('thank_you_onboarding_p4')}
              </span>
            </a>
          </Text>
        </h3>
      </Text>
    </div>
  );
};
