import React from 'react';
import clsx from 'clsx';

import { DoneIcon } from '../icons/icons';
import './checkbox.scss';

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  isChecked: boolean;
  label: string;
}

export default function Checkbox({
  isChecked,
  onChange,
  label,
  name,
  className,
  ...rest
}: CheckboxProps) {
  return (
    <div className={clsx('checkbox-container', className)} {...rest}>
      <input
        type='checkbox'
        onChange={onChange}
        checked={isChecked}
        id={name}
        name={name}
      />
      <DoneIcon
        className={`checkbox ${isChecked ? 'checkbox--active' : ''}`}
        aria-hidden='true'
      />
      <label className='label-container' title={label} htmlFor={name}>
        <span>{label}</span>
      </label>
    </div>
  );
}
