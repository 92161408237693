import { useDebounce } from 'app/common/hooks';
import { AutocompleteInput } from 'app/common/shared/input/autocomplete-input';
import { Input } from 'app/common/shared/input/input';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import axios from 'axios';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Footer } from './action-footer';
import { stepsMapping } from './onboarding';
import { StepperHeader } from './stepper-header';

const isolatedAxios = axios.create();

export interface BaseUserAddressScreenValues {
  streetAddress: string;
  streetAddressLine2: string;
  city: string;
  state: string;
  postalCode: string;
}

interface UserAddressScreenValues extends BaseUserAddressScreenValues {
  address: {
    label: string;
    value: BaseUserAddressScreenValues;
    displayValue: string;
  };
}

export const UserAddressScreen = () => {
  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { isValid },
  } = useForm<UserAddressScreenValues>({
    mode: 'onChange',
    defaultValues: (stepsData[currentStep] as UserAddressScreenValues) || {},
  });

  const { env } = useEnvironmentProvider();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
        jwt_token: string;
      };

      await isolatedAxios.post(
        `${env?.REACT_APP_BASE_URL}/nextgen/Users/UpdateAddressInformation`,
        {
          streetAddress: data.streetAddress,
          streetAddressLine2: data.streetAddressLine2,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
        },
        { headers: { Authorization: `Bearer ${jwt_token}` } }
      );

      dispatchSetStepData(currentStep, data);

      dispatchSetCurrentStep((currentStep + 1) as number);
    } catch (error) {
      console.error(error);

      toast.error('Failed to submit address');
    }
  });

  const [suggestions, setSuggestions] = React.useState<
    BaseUserAddressScreenValues[]
  >([]);

  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 400);

  const formattedSuggestions = useMemo(
    () =>
      suggestions.map((suggestion) => {
        return {
          label: `${[
            suggestion.streetAddress,
            suggestion.city,
            `${suggestion.state} ${suggestion.postalCode}`,
          ].join(', ')}`,
          displayValue: suggestion.streetAddress,
          value: suggestion,
        };
      }),
    [suggestions]
  );

  const address = watch('address');

  useEffect(() => {
    if (address?.value) {
      setValue('streetAddress', address.value.streetAddress, {
        shouldValidate: true,
      });
      setValue('streetAddressLine2', address.value.streetAddressLine2, {
        shouldValidate: true,
      });
      setValue('city', address.value.city, { shouldValidate: true });
      setValue('state', address.value.state, { shouldValidate: true });
      setValue('postalCode', address.value.postalCode, {
        shouldValidate: true,
      });
    }
  }, [address?.value]);

  useEffect(() => {
    const controller = new AbortController();

    const signal = controller.signal;

    if (debouncedSearchTerm) {
      const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
        jwt_token: string;
      };

      isolatedAxios
        .post(
          `${env?.REACT_APP_BASE_URL}/nextgen/Users/AddressAutoComplete`,
          {
            streetAddress: searchTerm,
          },
          {
            signal,
            headers: { Authorization: `Bearer ${jwt_token}` },
          }
        )
        .then((results) => {
          setSuggestions(results.data);
        });
    } else {
      setSuggestions([]);
    }

    return () => {
      controller.abort();
    };
  }, [debouncedSearchTerm]);

  return (
    <>
      <StepperHeader step={2} totalSteps={3} title={t('address_information')} />
      <form
        onSubmit={onSubmit}
        className='py-8 px-6 gap-6 flex h-full flex-col'
      >
        <AutocompleteInput
          label={t('street_address') + ' *'}
          items={formattedSuggestions}
          onSearchChange={(value) => setSearchTerm(value)}
          displayValue
          control={control as any}
          {...register('address', { required: true })}
        />
        <Input
          label={t('street_address_line_2')}
          showOptionalLabel
          {...register('streetAddressLine2')}
        />
        <Input
          label={t('city') + ' *'}
          required
          {...register('city', { required: true })}
        />
        <Input
          label={t('state') + ' *'}
          required
          {...register('state', { required: true })}
        />
        <Input
          label={t('postal_code') + ' *'}
          required
          {...register('postalCode', { required: true })}
        />
        <Footer disabled={!isValid} />
      </form>
    </>
  );
};
