import React from 'react';
import { useOverlay } from './use-overlay';

export const Overlay = () => {
  const { show, content } = useOverlay();

  if (!show) {
    return null;
  }

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-dark/60 z-40'>
      {content}
    </div>
  );
};
