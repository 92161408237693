import { ArrowLeftIcon, MobileLoaderIcon } from 'app/common/shared/icons/icons';
import { PasswordInput } from 'app/common/shared/input/input';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import { Wizard } from 'app/common/shared/wizard/wizard';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from './action-footer';
import { UserAddressScreen } from './user-address-screen';
import { UserInformationScreen } from './user-information-screen';
import { UserRidesScreen } from './user-rides-screen';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { toast } from 'react-toastify';
import { PhoneInput } from 'app/common/shared/phone-input/phone-input';
import { LanguageScreen } from './language-screen';
import { InstructionsScreen } from './instructions-screen';
import { Overlay } from 'app/common/shared/overlay/overlay';
import { useOverlay } from 'app/common/shared/overlay/use-overlay';
import { ThankYouScreen } from './thank-you-screen';
import { GoSelfResponse, UserSelfResponse } from 'app/common/types';
import { userStatuses } from 'app/common/constants';
import { DriverContract } from './driver-contract';
import { DriverConfirmAddress } from './driver-confirm-address';
import { DriverUpdateAddress } from './driver-update-address';
import {
  checkOnboardingRedirect,
  translateUserSelfResponse,
} from '../authentication/auth-provider';

export type PhoneNumberScreenValues = {
  maskedPhoneNumber: string;
  phoneNumber: string;
  phoneNumberCodeId: number;
  phoneNumberWithCountryCode: string;
};

const PhoneNumberScreen = () => {
  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<PhoneNumberScreenValues>({
    mode: 'onChange',
    defaultValues: (stepsData[currentStep] as PhoneNumberScreenValues) || {},
  });

  const [isPhoneValid, setIsPhoneValid] = React.useState(false);

  const { env } = useEnvironmentProvider();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await axios.post(
        `${env?.REACT_APP_BASE_URL}/nextgen/Users/CreateUser`,
        {
          phoneNumber: data.phoneNumber,
          phoneNumberCodeId: data.phoneNumberCodeId,
        }
      );

      dispatchSetStepData(currentStep, { ...data, ...response.data });

      dispatchSetCurrentStep((currentStep + 1) as number);
    } catch (error) {
      console.error(error);

      toast.error('Failed to submit phone number');
    }
  });

  const { t } = useTranslation();

  useEffect(() => {
    register('phoneNumberCodeId', { required: true });

    register('phoneNumber', {
      required: true,
    });

    register('phoneNumberWithCountryCode', { required: true });
  }, [register]);

  return (
    <form
      onSubmit={onSubmit}
      className='py-8 px-6 h-full relative flex flex-col'
    >
      <div className='flex items-center mb-8 gap-3'>
        <ArrowLeftIcon
          onClick={() => dispatchSetCurrentStep(currentStep - 1)}
        />
        <Text variant='h4Bold'>
          <h4 className='m-0'>Enter your Phone Number</h4>
        </Text>
      </div>
      <PhoneInput
        inputProps={{
          autoFocus: true,
          type: 'tel',
          ...register('maskedPhoneNumber', {
            required: true,
            validate: () => isPhoneValid,
          }),
          value: watch('maskedPhoneNumber'),
        }}
        onCountryCodeChange={() => {
          setValue('maskedPhoneNumber', '');
        }}
        onAccept={({ maskedValue, selectedValue, unmaskedValue }) => {
          const shouldValidateMask = !!selectedValue.format;

          setIsPhoneValid(
            shouldValidateMask
              ? `${maskedValue.replace('_', '')}` === maskedValue
              : true
          );

          setValue('phoneNumberCodeId', selectedValue.id, {
            shouldValidate: true,
          });

          setValue('phoneNumber', unmaskedValue, { shouldValidate: true });

          setValue('maskedPhoneNumber', maskedValue, { shouldValidate: true });

          setValue(
            'phoneNumberWithCountryCode',
            `+${selectedValue.dialing_code}${unmaskedValue}`,
            {
              shouldValidate: true,
            }
          );
        }}
      />
      <Text variant='bodyMedium'>
        <p className='text-gray-medium m-0 mt-1'>
          {t('phone_number_sms_password')}
        </p>
      </Text>
      <Footer disabled={!isValid} />
      <div className='self-center'>
        <Link to={{ pathname: '/' }} className='no-underline'>
          <Text variant='h5SemiBold'>
            <h5 className='text-primary m-0'>{t('user_has_account')}</h5>
          </Text>
        </Link>
      </div>
    </form>
  );
};

type PasswordScreenValues = {
  password: string;
};

const isolatedAxios = axios.create();

const LoaderOverlayMessage = () => {
  return (
    <div className='pr-4 pl-2 h-14 bg-[#DAD9D9] flex rounded-xl items-center'>
      <MobileLoaderIcon className='h-10 w-10 text-white mr-1' />
      <Text variant='bodyRegularSmall'>
        <p className='m-0 inline-flex'>Resending SMS...</p>
      </Text>
    </div>
  );
};

const PasswordScreen = () => {
  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setError,
  } = useForm<PasswordScreenValues>({
    mode: 'onChange',
    defaultValues: (stepsData[currentStep] as PasswordScreenValues) || {},
  });

  const { env } = useEnvironmentProvider();

  const { showOverlay } = useOverlay();

  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const previousStepData = stepsData[
        stepsMapping.PHONE_NUMBER
      ] as PhoneNumberScreenValues & { tenantId: string };

      const response = await isolatedAxios.post(
        `${env?.REACT_APP_BASE_URL}/users/authenticate`,
        {
          tenant_id: previousStepData.tenantId,
          phone_number_login: {
            phone_number: previousStepData.phoneNumberWithCountryCode,
            password: data.password,
          },
        }
      );

      const token = response.data?.jwt_token;

      if (token) {
        const userResponse = await isolatedAxios.post<GoSelfResponse>(
          `${env?.REACT_APP_BASE_URL}/users/self`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-type': 'application/json',
            },
          }
        );

        const user = translateUserSelfResponse(userResponse.data);

        const onboardingStatus = user?.userOnboarding.status;

        dispatchSetStepData(currentStep, {
          jwt_token: response.data?.jwt_token,
        });

        const shouldRedirect = checkOnboardingRedirect({
          tenantId: previousStepData.tenantId,
          token: response.data?.jwt_token,
          user: user,
          userId: user?.id,
          phoneNumber: previousStepData.phoneNumberWithCountryCode,
        });

        if (shouldRedirect) {
          navigate('/onboarding');

          return;
        }

        if (
          [userStatuses.READY_FOR_APPROVAL, userStatuses.NOT_APPROVED].find(
            (status) => status === onboardingStatus
          )
        ) {
          dispatchSetCurrentStep(stepsMapping.THANK_YOU);

          return;
        }

        if (
          [userStatuses.READY_TO_SHIP, userStatuses.READY_TO_ACTIVATE].find(
            (status) => status === onboardingStatus
          )
        ) {
          navigate('/');

          return;
        }

        dispatchSetCurrentStep((currentStep + 1) as number);
      }
    } catch (error) {
      console.error(error);

      if ((error as AxiosError)?.response?.status === 401) {
        setError('password', { type: 'custom', message: 'Incorrect password' });

        return;
      }

      toast.error('Failed to submit phone number');
    }
  });

  const { t } = useTranslation();

  const onResendClick = async () => {
    const previousStepData = stepsData[
      stepsMapping.PHONE_NUMBER
    ] as PhoneNumberScreenValues & { tenantId: number };

    try {
      showOverlay({
        show: true,
        content: <LoaderOverlayMessage />,
      });

      await isolatedAxios.post(
        `${env?.REACT_APP_BASE_URL}/nextgen/Users/ResendPassword`,
        {
          phoneNumber: previousStepData.phoneNumber,
          phoneNumberCodeId: previousStepData.phoneNumberCodeId,
        }
      );
    } catch (error) {
      console.error(error);

      toast.error('Failed to resend password');
    } finally {
      showOverlay({
        show: false,
        content: null,
      });
    }
  };

  const bottomLabel = errors.password?.message ? (
    <p className='text-primary-hover m-0'>{errors.password.message}</p>
  ) : (
    <p className='text-gray-medium m-0'>{t('enter_sms_password')}</p>
  );

  return (
    <form
      onSubmit={onSubmit}
      className='py-8 px-6 h-full relative flex flex-col'
    >
      <div className='flex items-center mb-8 gap-3'>
        <ArrowLeftIcon
          onClick={() => dispatchSetCurrentStep(currentStep - 1)}
        />
        <Text variant='h4Bold'>
          <h4 className='m-0'>Enter your Password</h4>
        </Text>
      </div>
      <PasswordInput
        label='Password'
        type='password'
        required
        hasError={!!errors.password?.message}
        {...register('password', { required: true })}
        bottomLabel={<Text variant='bodyMedium'>{bottomLabel}</Text>}
      />
      <div className='self-center mt-12'>
        <Text variant='h5SemiBold'>
          <h5 className='text-gray-medium m-0'>
            {t('not_received_sms')}{' '}
            <span
              className='text-primary cursor-pointer'
              onClick={onResendClick}
            >
              {t('resend')}
            </span>
          </h5>
        </Text>
      </div>
      <Footer disabled={!isValid} />
    </form>
  );
};

export const stepsMapping = {
  LANGUAGE: 1,
  INSTRUCTIONS: 2,
  PHONE_NUMBER: 3,
  PASSWORD: 4,
  USER_INFORMATION: 5,
  USER_ADDRESS: 6,
  USER_RIDES: 7,
  DRIVER_CONTRACT: 8,
  DRIVER_CONFIRM_ADDRESS: 9,
  DRIVER_UPDATE_ADDRESS: 10,
  THANK_YOU: 11,
} as const;

// the rendering order depends also on the ordering in the array
const initialSteps = [
  {
    component: <LanguageScreen />,
    step: stepsMapping.LANGUAGE,
  },
  {
    component: <InstructionsScreen />,
    step: stepsMapping.INSTRUCTIONS,
  },
  {
    component: <PhoneNumberScreen />,
    step: stepsMapping.PHONE_NUMBER,
  },
  {
    component: <PasswordScreen />,
    step: stepsMapping.PASSWORD,
  },
  // steps for registering
  {
    component: <UserInformationScreen />,
    step: stepsMapping.USER_INFORMATION,
  },
  {
    component: <UserAddressScreen />,
    step: stepsMapping.USER_ADDRESS,
  },
  {
    component: <UserRidesScreen />,
    step: stepsMapping.USER_RIDES,
  },
  {
    component: <DriverContract />,
    step: stepsMapping.DRIVER_CONTRACT,
  },
  {
    component: <DriverConfirmAddress />,
    step: stepsMapping.DRIVER_CONFIRM_ADDRESS,
  },
  {
    component: <DriverUpdateAddress />,
    step: stepsMapping.DRIVER_UPDATE_ADDRESS,
  },
  {
    component: <ThankYouScreen />,
    step: stepsMapping.THANK_YOU,
  },
];

const InitialSteps = () => {
  return <Wizard wizardKey={wizardKeys.ONBOARDING} steps={initialSteps} />;
};

export const Onboarding = () => {
  return (
    <>
      <Overlay />
      <div className='flex flex-col h-full mx-auto md:w-3/4 md:max-w-screen-lg max-w-full relative'>
        <InitialSteps />
      </div>
    </>
  );
};
