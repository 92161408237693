import { Wizard } from 'app/common/shared/wizard/wizard';
import React, { useEffect } from 'react';
import { DriverConfirmAddress } from '../onboarding/driver-confirm-address';
import clsx from 'clsx';
import { DriverUpdateAddress } from '../onboarding/driver-update-address';
import {
  dispatchUpdateUser,
  useAuthStore,
} from '../authentication/use-auth-store';
import { userStatuses } from 'app/common/constants';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import { DriverConnect } from './onboarding/driver-connect';
import { useQuery } from 'app/common/hooks/use-query';
import { PaymentStatus } from 'app/common/types';
import { useAuth } from '../authentication/auth-provider';
import { ThankYouScreen } from '../onboarding/thank-you-screen';
import { DriverContract } from '../onboarding/driver-contract';

export const stepsMapping = {
  STRIPE_CONNECT: 1,
};

const initialSteps = [
  {
    component: <DriverConnect />,
    step: stepsMapping.STRIPE_CONNECT,
  },
];

const InitialSteps = () => {
  const { user } = useAuthStore();

  const onboardingStatus = user?.userOnboarding?.status;

  if (
    [userStatuses.NOT_APPROVED, userStatuses.READY_FOR_APPROVAL].find(
      (status) => status === onboardingStatus
    )
  ) {
    return <ThankYouScreen />;
  }

  return (
    <Wizard wizardKey={wizardKeys.DRIVER_ONBOARDING} steps={initialSteps} />
  );
};

interface ActionFooterProps {
  className?: string;
  children?: React.ReactNode;
}

export const ActionFooter = ({ children, className }: ActionFooterProps) => {
  return (
    <div
      className={clsx(
        'md:w-3/4 md:max-w-screen-lg w-full px-6 mt-auto flex fixed bottom-0 z-10 bg-white',
        className
      )}
    >
      {children}
    </div>
  );
};

export const DriverOnboarding = () => {
  const { user } = useAuthStore();

  const { dispatchSetCurrentStep } = useWizardActions(
    wizardKeys.DRIVER_ONBOARDING
  );

  const { auth } = useAuth();

  const { data, loading } = useQuery<PaymentStatus>('payments/status', {
    user_id: auth.userId,
    provider_id: 'stripe',
  });

  useEffect(() => {
    if (user.userOnboarding?.status === userStatuses.READY_TO_ACTIVATE) {
      if (data?.onboarded) {
        dispatchUpdateUser({ skipDriverOnboarding: true });

        return;
      }

      dispatchSetCurrentStep(stepsMapping.STRIPE_CONNECT);
    }
  }, [user.userOnboarding, data?.onboarded]);

  if (loading) {
    return null;
  }

  return (
    <div className='bg-white w-full h-full flex grow'>
      <div className='flex flex-col flex-grow mx-auto md:w-3/4 md:max-w-screen-lg w-full relative bg-white'>
        <InitialSteps />
      </div>
    </div>
  );
};
