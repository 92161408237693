import React from 'react';
import { useWizardStore, WizardKeys } from './use-wizard-store';

interface WizardProps {
  steps: { step: number; component: React.ReactNode }[];
  wizardKey: WizardKeys;
}

export const Wizard = (props: WizardProps) => {
  const { currentStep } = useWizardStore((state) => state[props.wizardKey]);

  return <>{props.steps[currentStep - 1]?.component}</>;
};
