import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { Authentication } from 'app/pages/login/login';
import { Onboarding } from 'app/pages/onboarding/onboarding';
import AlfiSpinner from 'app/common/shared/Spinners/AlfiSpinner/AlfiSpinner';
import { ForgotPassword } from 'app/pages/login/forgot-password';

interface ProtectedRoutesProps {
  isAllowed: boolean;
  children?: React.ReactNode;
}

const SuspenseFallback = ({ component }) => {
  return (
    <Suspense
      fallback={
        <div className='loader-container'>
          <AlfiSpinner size={30} />
        </div>
      }
    >
      {component}
    </Suspense>
  );
};

export const ProtectedRoutes = ({
  isAllowed,
  children,
}: ProtectedRoutesProps) => {
  if (!isAllowed) {
    return (
      <Routes>
        <Route
          path='/'
          element={<SuspenseFallback component={<Authentication />} />}
        />
        <Route
          path='/forgot-password'
          element={<SuspenseFallback component={<ForgotPassword />} />}
        />
        <Route
          path='/onboarding'
          element={<SuspenseFallback component={<Onboarding />} />}
        />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    );
  }

  // outlet renders the children routes
  return <>{children || <Outlet />}</>;
};
