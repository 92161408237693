import React from 'react';

// Components
import { Breadcrumbs, Link } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'app/common/hooks/use-location';

const Breadcrumb = () => {
  const { pathname, state } = useLocation<any>();
  const navigate = useNavigate();
  const crumbs = pathname.split('/').filter((i) => !!i); // Remove empty string at beginning

  // These get displayed, just capitalizing them
  const crumbsToDisplay = crumbs.map(
    (item) => item.charAt(0).toUpperCase() + item.slice(1)
  );

  const handleNavigation = (index) => {
    const url = crumbs.slice(0, index + 1).join('/');
    navigate(`/${url}`, { state });
  };

  const showAdvertisersCrumb = () => {
    const pathsToIgnore = ['devices', 'executive', 'user'];
    const base = pathname.split('/')[1]; // 0 will be empty string
    if (pathsToIgnore.indexOf(base) !== -1) {
      return null;
    }

    // Check if we are already on Advertisers page
    return pathname === '/' ? (
      // <h4 style={{ fontWeight: 400 }}>Advertisers</h4>
      <h4 style={{ fontWeight: 400 }}>Users</h4>
    ) : (
      <Link
        underline='always'
        style={{ cursor: 'pointer', color: '#fff' }}
        onClick={() =>
          navigate('/', {
            state,
          })
        }
      >
        Advertisers
      </Link>
    );
  };

  return (
    <Breadcrumbs style={{ color: '#fff' }}>
      {showAdvertisersCrumb()}
      {crumbsToDisplay.map((name, i) =>
        name && i !== crumbsToDisplay.length - 1 ? (
          <Link
            underline='always'
            style={{ cursor: 'pointer', color: '#fff' }}
            onClick={() => handleNavigation(i)}
            key={i}
          >
            {name}
          </Link>
        ) : (
          <h4 key={i} style={{ fontWeight: 400 }}>
            {name}
          </h4>
        )
      )}
    </Breadcrumbs>
  );
};

export { Breadcrumb };
