import React from 'react';
import { Box } from '@material-ui/core';

export function Main({ children }) {
  return (
    <main className='w-full flex flex-col min-h-full bg-gray-light pt-16 overflow-x-hidden'>
      {children}
    </main>
  );
}
