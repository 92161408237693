import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConnnectStripeIllustration } from 'app/common/shared/icons/illustrations';
import { ActionFooter } from '../driver-onboarding';
import { Button } from 'app/common/shared/button/button';
import { Text } from 'app/common/shared/text/text';
import { StripeLogoIcon } from 'app/common/shared/icons/icons';
import {
  dispatchUpdateUser,
  useAuthStore,
} from '../../authentication/use-auth-store';
import { toast } from 'react-toastify';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { useAuth } from 'app/pages/authentication/auth-provider';
import axios from 'axios';

export const DriverConnect = () => {
  const { t } = useTranslation();

  const { user, legacyUser } = useAuthStore();

  const { env } = useEnvironmentProvider();
  const baseUrl = env?.REACT_APP_BASE_URL;

  const { auth } = useAuth();
  const enroll = async () => {
    try {
      if (!user) {
        toast.error(t('driver_onboarding.driver_connect.connect_unable'));
        return;
      }

      const url = `${baseUrl}/payments/enroll`;

      const response = await axios.post(url, {
        tenantId: auth.tenantId,
        userId: auth.userId,
        providerId: 'stripe',
        failureUrl: window.location.href,
        successUrl: window.location.href,
        user: legacyUser.user,
      });

      const { data } = response;

      window.location.href = data.onboarding_url as string;
    } catch (err) {
      toast.error(t('driver_onboarding.driver_connect.connect_error'));

      console.error(err);
    }
  };

  return (
    <div className='flex flex-col items-center pt-10'>
      <ConnnectStripeIllustration className='mb-5' />
      <Text variant='h4Bold'>
        <h4 className='m-0 mb-8 text-center'>
          {t('driver_onboarding.driver_connect.connect_stripe')}!
        </h4>
      </Text>
      <Text variant='h5Regular'>
        <h5 className='m-0 mb-8 text-center'>
          {t('driver_onboarding.driver_connect.p1')}
        </h5>
      </Text>

      <ActionFooter className='pb-6 pt-1 bg-white'>
        <div className='flex flex-col w-full gap-4 relative'>
          <Button
            type='button'
            variant='outline'
            className='w-full py-4'
            onClick={() => {
              dispatchUpdateUser({ skipDriverOnboarding: true });
            }}
          >
            {t('driver_onboarding.driver_connect.skip_connect')}
          </Button>
          <Button
            className='w-full h-[50px] py-4 hover:!bg-[#6273E9] bg-[#6273E9]'
            onClick={enroll}
          >
            <StripeLogoIcon className='absolute left-2' />
            {t('driver_onboarding.driver_connect.connect_stripe')}
          </Button>
        </div>
      </ActionFooter>
    </div>
  );
};
