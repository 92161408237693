import {
  AlfiLogo,
  FlagENIcon,
  FlagESIcon,
  NetworkIcon,
} from 'app/common/shared/icons/icons';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface LanguageCardProps {
  language: string;
  onClick: () => void;
  icon: React.ReactNode;
}

const LanguageCard = ({ language, onClick, icon }: LanguageCardProps) => {
  return (
    <button
      className='flex items-center p-6 bg-white rounded-lg shadow-card outline-none border-0'
      onClick={onClick}
    >
      <div className='flex items-center gap-4'>
        {icon}
        <Text variant='h5SemiBold'>
          <h5 className='m-0'>{language}</h5>
        </Text>
      </div>
    </button>
  );
};

export const LanguageScreen = () => {
  const { t, i18n } = useTranslation();

  const { currentStep } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep } = useWizardActions(wizardKeys.ONBOARDING);

  const onChangeLanguage = async (language: 'en' | 'es') => {
    await i18n.changeLanguage(language);

    dispatchSetCurrentStep((currentStep + 1) as number);
  };

  return (
    <div className='py-8 px-6 h-full relative flex flex-col'>
      <AlfiLogo className='h-11 w-40 self-center mb-8' />
      <div className='flex items-center gap-2 mb-4'>
        <NetworkIcon />
        <Text variant='h4Bold'>
          <h4 className='m-0'>Choose your language</h4>
        </Text>
      </div>
      <Text variant='h5Regular'>
        <h5 className='m-0 mb-8'>Seleccione su idioma</h5>
      </Text>
      <div className='w-full flex flex-col gap-4'>
        <LanguageCard
          language='English'
          onClick={() => onChangeLanguage('en')}
          icon={<FlagENIcon />}
        />
        <LanguageCard
          language='Español'
          onClick={() => onChangeLanguage('es')}
          icon={<FlagESIcon />}
        />
      </div>
      <div className='self-center mt-auto'>
        <Link to={{ pathname: '/' }} className='no-underline'>
          <Text variant='h5SemiBold'>
            <h5 className='text-primary m-0'>{t('user_has_account')}</h5>
          </Text>
        </Link>
      </div>
    </div>
  );
};
