import { Dialog, Transition } from '@headlessui/react';
import { CloseIcon } from 'app/common/shared/icons/icons';
import React, { Fragment } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: React.ReactNode;
  panelClassNames?: string;
}

export const Modal = ({
  isOpen,
  onClose,
  content,
  panelClassNames = '',
}: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-dark bg-opacity-60' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className={`${panelClassNames} max-w-md transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all relative`}
              >
                <button
                  className='border-none rounded-full p-1 absolute right-6 top-6 bg-gray-light/20 leading-[0px]'
                  type='button'
                  onClick={onClose}
                >
                  <CloseIcon />
                </button>
                {content}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
