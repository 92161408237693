import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Layout from './pages/layout/layout';
import { useAuth } from './pages/authentication/auth-provider';
import { useTokenProvider } from 'providers/TokenProvider';
import AlfiSpinner from './common/shared/Spinners/AlfiSpinner/AlfiSpinner';
import { roles } from './common/constants';
import { ProtectedRoutes } from './routes/protected-routes';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/scss/App.scss';
import '../i18n';
import { Auth } from './common/types';

const getRoutes = (role: Auth['role']) => {
  switch (role) {
    case roles.SUPERADMIN:
      const AdminRoutes = lazy(() => import('./routes/admin-routes'));
      return <AdminRoutes />;
    case roles.OPSADMIN:
      const OpsRoutes = lazy(() => import('./routes/ops-routes'));
      return <OpsRoutes />;
    case roles.DRIVER:
      const DriverRoutes = lazy(() => import('./routes/driver-routes'));
      return <DriverRoutes />;
    case roles.KIOSKOWNER:
      const KioskRoutes = lazy(() => import('./routes/kiosk-routes'));
      return <KioskRoutes />;
  }
};

export const SuspenseFallback = ({ component }) => {
  return (
    <Suspense
      fallback={
        <div className='loader-container'>
          <AlfiSpinner size={30} />
        </div>
      }
    >
      {component}
    </Suspense>
  );
};

const RoleRoutes = ({ role }: { role: Auth['role'] }) => {
  return <SuspenseFallback component={getRoutes(role)} />;
};

function App() {
  const { auth } = useAuth();
  const { token } = useTokenProvider();

  return (
    <div className='app'>
      <Routes>
        <Route path='/*' element={<ProtectedRoutes isAllowed={!!token} />}>
          <Route element={<Layout />}>
            <Route index element={<RoleRoutes role={auth.role} />} />
            <Route path='*' element={<RoleRoutes role={auth.role} />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer position='bottom-center' hideProgressBar />
    </div>
  );
}

export default App;
