import { AlfiLogo, ArrowLeftIcon } from 'app/common/shared/icons/icons';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as OnboardingStepOneIcon } from 'assets/onboarding-step-one.svg';
import { ReactComponent as OnboardingStepTwoIcon } from 'assets/onboarding-step-two.svg';
import { useInView } from 'react-intersection-observer';
import { Footer } from './action-footer';

interface InstructionStepProps {
  description: React.ReactNode;
  icon: React.ReactNode;
  title: string;
}

const InstructionStep = ({
  title,
  description,
  icon,
}: InstructionStepProps) => {
  return (
    <div className='px-6 mx-auto text-center'>
      {icon}
      <Text variant='h4Bold'>
        <h5 className='m-0 mt-8 mb-6'>{title}</h5>
      </Text>
      <Text variant='h5Regular'>
        <h5 className='m-0 whitespace-pre-line'>{description}</h5>
      </Text>
    </div>
  );
};

const InstructionStepper = ({ active, onClick }) => {
  return (
    <div
      className={`rounded-full transition-colors cursor-pointer h-3 w-3 ${
        active ? 'bg-primary' : 'bg-gray-medium-light'
      }`}
      onClick={onClick}
    />
  );
};

export const InstructionsScreen = () => {
  const { t } = useTranslation();

  const { currentStep } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep } = useWizardActions(wizardKeys.ONBOARDING);

  const stepOneRef = useRef<HTMLDivElement>();

  const stepTwoRef = useRef<HTMLDivElement>();

  const { ref: stepOneVisibileRef, inView: stepOneInView } = useInView({
    threshold: 0.8,
  });

  const { ref: stepTwoVisibleRef, inView: stepTwoInView } = useInView({
    threshold: 0.8,
  });

  const setStepOneRefs = useCallback(
    (node) => {
      stepOneRef.current = node;
      stepOneVisibileRef(node);
    },
    [stepOneVisibileRef]
  );

  const setStepTwoRefs = useCallback(
    (node) => {
      stepTwoRef.current = node;
      stepTwoVisibleRef(node);
    },
    [stepTwoVisibleRef]
  );

  return (
    <form
      className='py-8 h-full relative flex flex-col'
      onSubmit={() => {
        dispatchSetCurrentStep((currentStep + 1) as number);
      }}
    >
      <div className='mb-8 flex flex-col justify-center'>
        <div className='flex absolute pl-6'>
          <ArrowLeftIcon
            className='cursor-pointer'
            onClick={() => dispatchSetCurrentStep(currentStep - 1)}
          />
        </div>
        <AlfiLogo className='h-11 w-40 self-center' />
      </div>
      <div className='snap-mandatory snap-x flex w-full relative overflow-x-auto no-scrollbar mb-[15%]'>
        <div
          className='snap-center w-full shrink-0 flex items-center gap-2 mb-4'
          ref={setStepOneRefs}
        >
          <InstructionStep
            icon={
              <OnboardingStepOneIcon
                className='cursor-pointer'
                onClick={() => {
                  stepTwoRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
              />
            }
            title={t('onboarding_step_one')}
            description={t('onboarding_instructions_p1')}
          />
        </div>
        <div
          className='snap-center  w-full shrink-0 flex items-center gap-2 mb-4'
          ref={setStepTwoRefs}
        >
          <InstructionStep
            icon={
              <OnboardingStepTwoIcon
                className='cursor-pointer'
                onClick={() => {
                  stepOneRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
              />
            }
            title={t('onboarding_step_two')}
            description={t('onboarding_instructions_p2')}
          />
        </div>
      </div>
      <div className='self-center flex gap-4'>
        <InstructionStepper
          active={stepOneInView}
          onClick={() => {
            stepOneRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        />
        <InstructionStepper
          active={stepTwoInView}
          onClick={() => {
            stepTwoRef.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </div>
      <Footer className='px-6' />
      <div className='self-center'>
        <Link to={{ pathname: '/' }} className='no-underline'>
          <Text variant='h5SemiBold'>
            <h5 className='text-primary m-0'>{t('user_has_account')}</h5>
          </Text>
        </Link>
      </div>
    </form>
  );
};
