import {
  Location as RouterLocation,
  useLocation as useRouterLocation,
} from 'react-router-dom';

export const useLocation = <T>() => {
  type Location = RouterLocation & { state: T };

  return useRouterLocation() as Location;
};
