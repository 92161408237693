import { ArrowLeftIcon } from 'app/common/shared/icons/icons';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import React from 'react';

interface StepperHeaderProps {
  title?: React.ReactNode;
  step: number;
  totalSteps: number;
}

export const StepperHeader = ({
  title,
  step,
  totalSteps,
}: StepperHeaderProps) => {
  const { currentStep } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep } = useWizardActions(wizardKeys.ONBOARDING);

  return (
    <div className='sticky top-0 z-10'>
      <div className='w-full pt-6 pb-5 bg-white px-6 flex items-center text-center'>
        <div className='flex absolute'>
          <ArrowLeftIcon
            onClick={() => dispatchSetCurrentStep(currentStep - 1)}
          />
          <Text variant='bodyBold'>
            <p className='m-0 ml-3'>
              {step}/{totalSteps}
            </p>
          </Text>
        </div>
        <div className='w-full'>
          <Text variant='h5ExtraBold'>
            <h5 className='m-0'>{title}</h5>
          </Text>
        </div>
      </div>
      <div className='bg-gray-medium-light w-full'>
        <div
          className={`bg-primary-gradient min-h-[10px] rounded-r-xl`}
          style={{ width: `${(step / totalSteps) * 100}%` }}
        />
      </div>
    </div>
  );
};
