import { useDebounce } from 'app/common/hooks';
import { Button } from 'app/common/shared/button/button';
import { AutocompleteInput } from 'app/common/shared/input/autocomplete-input';
import { Input } from 'app/common/shared/input/input';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import axios from 'axios';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { stepsMapping } from './onboarding';

export interface BaseUserAddressScreenValues {
  streetAddress: string;
  streetAddressLine2: string;
  city: string;
  state: string;
  postalCode: string;
}

interface UserAddressScreenValues extends BaseUserAddressScreenValues {
  address: {
    label: string;
    value: BaseUserAddressScreenValues;
    displayValue: string;
  };
}

const isolatedAxios = axios.create();

export const DriverUpdateAddress = () => {
  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { isValid },
  } = useForm<UserAddressScreenValues>({
    mode: 'onChange',
    defaultValues: (stepsData[currentStep] as UserAddressScreenValues) || {},
  });

  const { env } = useEnvironmentProvider();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
        jwt_token: string;
      };

      await isolatedAxios.patch(
        `${env?.REACT_APP_BASE_URL}/nextgen/Users/ConfirmShippingAddress`,
        {
          shippingAddress: {
            shippingCity: data.city,
            shippingStateRegion: data.state,
            shippingPostalCode: data.postalCode,
            shippingStreet: data.streetAddress,
            shippingStreetLine2: data.streetAddressLine2,
            shippingCountry: 'United States',
          },
        },
        {
          headers: { Authorization: `Bearer ${jwt_token}` },
        }
      );

      const driverInfo = stepsData[stepsMapping.DRIVER_CONFIRM_ADDRESS] as {
        name: string;
      };

      dispatchSetStepData(stepsMapping.DRIVER_CONFIRM_ADDRESS, {
        name: driverInfo.name,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        street: data.streetAddress,
        streetLine2: data.streetAddressLine2,
      });

      dispatchSetCurrentStep(stepsMapping.DRIVER_CONFIRM_ADDRESS as number);
    } catch (error) {
      console.error(error);

      toast.error(t('driver_onboarding.address_update_address.failed_submit'));
    }
  });

  const [suggestions, setSuggestions] = React.useState<
    BaseUserAddressScreenValues[]
  >([]);

  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 400);

  const formattedSuggestions = useMemo(
    () =>
      suggestions.map((suggestion) => {
        return {
          label: `${[
            suggestion.streetAddress,
            suggestion.city,
            `${suggestion.state} ${suggestion.postalCode}`,
          ].join(', ')}`,
          displayValue: suggestion.streetAddress,
          value: suggestion,
        };
      }),
    [suggestions]
  );

  const address = watch('address');

  useEffect(() => {
    if (address?.value) {
      setValue('streetAddress', address.value.streetAddress, {
        shouldValidate: true,
      });
      setValue('streetAddressLine2', address.value.streetAddressLine2, {
        shouldValidate: true,
      });
      setValue('city', address.value.city, { shouldValidate: true });
      setValue('state', address.value.state, { shouldValidate: true });
      setValue('postalCode', address.value.postalCode, {
        shouldValidate: true,
      });
    }
  }, [address?.value]);

  useEffect(() => {
    const controller = new AbortController();

    const signal = controller.signal;

    if (debouncedSearchTerm) {
      const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
        jwt_token: string;
      };

      isolatedAxios
        .post(
          `${env?.REACT_APP_BASE_URL}/nextgen/Users/AddressAutoComplete`,
          {
            streetAddress: searchTerm,
          },
          {
            signal,
            headers: { Authorization: `Bearer ${jwt_token}` },
          }
        )
        .then((results) => {
          setSuggestions(results.data);
        });
    } else {
      setSuggestions([]);
    }

    return () => {
      controller.abort();
    };
  }, [debouncedSearchTerm]);

  return (
    <>
      <form
        onSubmit={onSubmit}
        className='flex flex-col h-full gap-6 px-8 pt-6'
      >
        <div className='flex mb-6 text-center'>
          <Text variant='h5ExtraBold'>
            <h5 className='m-0 mx-auto'>
              {t('driver_onboarding.address_update_address.title')}
            </h5>
          </Text>
        </div>
        <AutocompleteInput
          label={t('street_address') + ' *'}
          items={formattedSuggestions}
          onSearchChange={(value) => setSearchTerm(value)}
          displayValue
          control={control as any}
          {...register('address', { required: true })}
        />
        <Input
          label={t('street_address_line_2')}
          showOptionalLabel
          {...register('streetAddressLine2')}
        />
        <Input
          label={t('city') + ' *'}
          required
          {...register('city', { required: true })}
        />
        <Input
          label={t('state') + ' *'}
          required
          {...register('state', { required: true })}
        />
        <Input
          label={t('postal_code') + ' *'}
          required
          {...register('postalCode', { required: true })}
        />
        <div className='w-full mt-auto flex sticky bottom-0 z-10 pb-6 pt-1 self-center'>
          <div className='flex flex-col w-full gap-4'>
            <Button
              variant='outline'
              type='button'
              onClick={() =>
                dispatchSetCurrentStep(
                  stepsMapping.DRIVER_CONFIRM_ADDRESS as number
                )
              }
              className='w-full py-4'
            >
              Cancel
            </Button>
            <Button disabled={!isValid} className='w-full py-4'>
              Confirm Shipping Address
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
