import { Button } from 'app/common/shared/button/button';
import { ArrowRightIcon } from 'app/common/shared/icons/icons';
import React from 'react';
import clsx from 'clsx';

interface FooterProps {
  disabled?: boolean;
  className?: string;
}

export const Footer = ({ disabled, className }: FooterProps) => {
  return (
    <div
      className={clsx(
        'w-fit left-full mt-auto flex items-end sticky bottom-0 z-10',
        className
      )}
    >
      <Button
        variant='filled'
        className='p-5 mb-6 rounded-full bg-primary-gradient disabled:text-white disabled:opacity-50 drop-shadow-floating'
        type='submit'
        disabled={disabled}
      >
        <ArrowRightIcon />
      </Button>
    </div>
  );
};
