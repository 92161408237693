import React, { forwardRef, Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { useController, UseControllerProps } from 'react-hook-form';

interface AutocompleteInputItem {
  value: unknown;
  label: string;
  displayValue: string;
}

interface AutocompleteInputProps {
  items: AutocompleteInputItem[];
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  label: React.ReactNode;
  displayValue?: boolean;
  name: string;
  onSearchChange: (searchTerm: string) => void;
}

export const AutocompleteInput = forwardRef<
  HTMLInputElement,
  AutocompleteInputProps & UseControllerProps
>((props: AutocompleteInputProps & UseControllerProps, ref) => {
  const {
    field: { value, onChange },
  } = useController(props);

  return (
    <Combobox value={value} onChange={onChange} nullable>
      <div className='relative font-redhat'>
        {props.label && (
          <Combobox.Label
            htmlFor={props.inputProps?.name}
            className='font-redhat text-base font-medium text-dark mb-1 block'
          >
            {props.label}
          </Combobox.Label>
        )}
        <Combobox.Input
          onChange={(e) => {
            props.onSearchChange(e.target.value);
          }}
          className='font-redhat w-full h-11 outline-none border border-solid border-gray-medium-light rounded-[4px] py-2 px-2 font-medium text-base text-gray hover:border-gray-medium focus:border-gray-medium transition-colors'
          displayValue={(item: AutocompleteInputItem) =>
            props.displayValue ? item?.displayValue : item?.label
          }
          ref={ref}
        />
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Combobox.Options className='absolute mt-1 max-h-64 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm p-0 pb-1'>
            <div className='relative h-full flex flex-col'>
              {props.items.length === 0 && props.inputProps?.value !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 font-medium text-base'>
                  Nothing found.
                </div>
              ) : (
                props.items.map((item, i) => (
                  <Combobox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-default select-none py-3 list-none px-4 font-medium text-base text-gray ${
                        active ? 'bg-gray-light' : ''
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <span
                        className={`block truncate ${
                          selected ? 'font-bold' : 'font-medium'
                        }`}
                      >
                        {item.label}
                      </span>
                    )}
                  </Combobox.Option>
                ))
              )}
            </div>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
});
