import React from 'react';
import './text.scss';

export const textVariantStyleMapping = {
  h1ExtraBold: 'h1-extra-bold',
  h2Bold: 'h2-bold',
  h3ExtraBold: 'h3-extrabold',
  h4Bold: 'h4-bold',
  h4SemiBold: 'h4-semibold',
  h5Regular: 'h5-regular',
  h5SemiBold: 'h5-semibold',
  h5ExtraBold: 'h5-extra-bold',
  bodyRegular: 'body-regular',
  bodyMedium: 'body-medium',
  bodyBold: 'body-bold',
  bodyRegularSmall: 'body-regular-sm',
  bodySemiBoldSmall: 'body-semibold-sm',
  bodyExtraBoldSmall: 'body-extrabold-sm',
  bodyRegularExtraSmall: 'body-regular-xs',
  bodyMediumExtraSmall: 'body-medium-xs',
} as const;

interface TextProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactElement;
  variant: keyof typeof textVariantStyleMapping;
}

export function Text({ children, variant, className }: TextProps) {
  return React.cloneElement(children, {
    className: `${textVariantStyleMapping[variant]} ${
      (children.props.className || className) ?? ''
    }`,
  });
}

Text.defaultProps = {
  variant: 'bodyRegular',
};
