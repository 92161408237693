import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';

import {
  Auth,
  JWT,
  UserSelfResponse,
  UsersViewResponse,
  GoSelfResponse,
} from '../../common/types';
import { useTokenProvider } from '../../../providers/TokenProvider';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  dispatchLogout,
  dispatchUpdateAuth,
  dispatchUpdateUser,
} from './use-auth-store';
import { useNavigate } from 'react-router-dom';
import { userStatuses } from 'app/common/constants';
import {
  useWizardActions,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import { stepsMapping } from '../onboarding/onboarding';

interface HandleLoginOptions {
  email?: string;
  phoneNumber?: string;
  password: string;
  tenant_id?: string;
  nextgen?: boolean;
}

const defaultAuthContext = {
  handleLogin: (options: HandleLoginOptions) => {},
  register: () => {},
  logout: () => {},
  auth: { tenantId: '', userId: '', role: '', user: undefined } as Auth,
  setAuth: (value: Auth) => {},
};

export const translateUserSelfResponse: (
  response: GoSelfResponse
) => UserSelfResponse = (response) => {
  return {
    ...response.user,
    userOnboarding: {
      ...response.onboarding_details,
      ...response.extension,
    },
  };
};

const AuthContext = createContext(defaultAuthContext);

const isolatedAxios = axios.create();

export const getUser = async (
  baseUrl: string,
  token: string,
  tenantId: string
) => {
  const userResponse = await isolatedAxios.post<GoSelfResponse>(
    baseUrl + '/users/self',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
    }
  );
  const self = translateUserSelfResponse(userResponse.data);
  console.log(self);
  const legacyUserResponse = await isolatedAxios.post<UsersViewResponse>(
    baseUrl + '/users/view',
    {
      tenant_id: tenantId,
      user_id: self.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return { user: self, legacyUser: legacyUserResponse.data };
};

export const checkOnboardingRedirect = ({
  user,
  phoneNumber,
  tenantId,
  userId,
  token,
}: {
  user: UserSelfResponse;
  phoneNumber?: string;
  tenantId: string;
  userId: string;
  token: string;
}) => {
  const { dispatchSetStepData, dispatchSetCurrentStep } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  if (
    [
      userStatuses.READY_FOR_INFO,
      userStatuses.READY_FOR_DRIVER_VERIFICATION,
      userStatuses.READY_FOR_CONTRACT,
      userStatuses.READY_FOR_ADDRESS_CONFIRMATION,
    ].find((status) => status === user?.userOnboarding?.status)
  ) {
    dispatchSetStepData(stepsMapping.PHONE_NUMBER, {
      phoneNumberCodeId: user.phoneCountryId,
      phoneNumber: user.phoneNumber,
      phoneNumberWithCountryCode: phoneNumber,
      tenantId,
      userId,
    });

    dispatchSetStepData(stepsMapping.PASSWORD, {
      jwt_token: token,
    });

    dispatchSetStepData(stepsMapping.USER_INFORMATION, {
      salutation: user.salutation ?? '',
      name: user.firstName ?? '',
      middleName: user.middleName ?? '',
      lastName: user.lastName ?? '',
      suffix: user.suffix ?? '',
      emailAddress: user.email ?? '',
    });

    if (user.userOnboarding?.status === userStatuses.READY_FOR_INFO) {
      dispatchSetCurrentStep(stepsMapping.USER_INFORMATION);

      return true;
    }

    const formattedAddress = `${[
      user.street,
      user.city,
      `${user.stateRegion} ${user.postalCode}`,
    ].join(', ')}`;

    const addressParts = {
      streetAddress: user.street ?? '',
      city: user.city ?? '',
      state: user.stateRegion ?? '',
      postalCode: user.postalCode ?? '',
    };

    dispatchSetStepData(stepsMapping.USER_ADDRESS, {
      // address object for the address autocomplete component
      address: {
        label: formattedAddress,
        value: addressParts,
        displayValue: user.street,
      },
      ...addressParts,
    });

    if (user.userOnboarding?.status === userStatuses.READY_FOR_CONTRACT) {
      dispatchSetCurrentStep(stepsMapping.DRIVER_CONTRACT);

      return true;
    }

    if (
      user.userOnboarding?.status ===
      userStatuses.READY_FOR_ADDRESS_CONFIRMATION
    ) {
      dispatchSetStepData(stepsMapping.DRIVER_CONFIRM_ADDRESS, {
        name: user.firstName,
        city: user.shippingCity || user.city,
        state: user.shippingStateRegion || user.stateRegion,
        postalCode: user.shippingPostalCode || user.postalCode,
        street: user.shippingStreet || user.street,
        streetLine2: user.shippingStreetLine2 || user.streetLine2,
      });

      dispatchSetCurrentStep(stepsMapping.DRIVER_CONFIRM_ADDRESS);

      return true;
    }

    dispatchSetCurrentStep(stepsMapping.USER_RIDES);

    return true;
  }

  return false;
};

function AuthProvider(props) {
  const { token, setToken } = useTokenProvider();
  const { env } = useEnvironmentProvider();

  const navigate = useNavigate();

  const [auth, setAuth] = useState(defaultAuthContext.auth);

  useEffect(() => {
    if (token && env?.REACT_APP_BASE_URL) {
      try {
        const { role, tenantID, userID }: JWT = jwt_decode(token);

        getUser(env?.REACT_APP_BASE_URL, token, tenantID)
          .then(({ user, legacyUser }) => {
            if (
              [
                userStatuses.READY_FOR_INFO,
                userStatuses.READY_FOR_DRIVER_VERIFICATION,
                userStatuses.READY_FOR_CONTRACT,
                userStatuses.READY_FOR_ADDRESS_CONFIRMATION,
              ].find((status) => status === user?.userOnboarding?.status)
            ) {
              navigate('/onboarding');

              return;
            }

            dispatchUpdateUser(user);

            dispatchUpdateAuth({ legacyUser });

            setAuth({ role, tenantId: tenantID, userId: userID });
          })
          .catch((e) => {
            console.error(e);

            logout();
          });
      } catch {
        logout();
      }
    }
  }, [token, env?.REACT_APP_BASE_URL]);

  const handleLogin = async ({
    email,
    phoneNumber,
    password,
    tenant_id,
    nextgen,
  }: HandleLoginOptions) => {
    const baseUrl = `${env?.REACT_APP_BASE_URL}`;

    const loginType = email
      ? { email_login: { email, password } }
      : { user_phone_login: { phone: phoneNumber, password } };

    const body = JSON.stringify({
      tenant_id,
      ...loginType,
    });

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await fetch(baseUrl + '/authenticate', {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        const error: { msg: string; code: string } = await response.json();
        if (response.status == 500) toast.error('Failed to login.');
        if (response.status == 401)
          if (error.msg == 'invalid login')
            toast.error('The provided password is incorrect');
          else {
            toast.error(
              'Your password has expired. Please check your email for your new password. Be sure to check your spam folder if you cannot find this email.',
              { autoClose: false }
            );
          }
        return;
      }
      const { jwt_token } = await response.json();
      if (!jwt_token || !tenant_id) return;

      const self = nextgen
        ? await getUser(env?.REACT_APP_BASE_URL || '', jwt_token, tenant_id)
        : undefined;

      const { role, tenantID, userID }: JWT = jwt_decode(jwt_token);

      if (self?.user) {
        const shouldRedirect = checkOnboardingRedirect({
          user: self?.user,
          tenantId: tenantID,
          userId: userID,
          token: jwt_token,
          phoneNumber,
        });

        if (shouldRedirect) {
          navigate('/onboarding');

          return;
        }

        dispatchUpdateAuth({ user: self.user, legacyUser: self.legacyUser });
      }

      setAuth({ role, tenantId: tenantID, userId: userID });

      setToken(jwt_token);

      return;
    } catch (error) {
      throw error;
    }
  };

  const register = () => {};

  const logout = () => {
    setToken('');

    dispatchLogout();
  };

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        logout,
        register,
        auth,
        setAuth,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
