import React from 'react';
import './AlfiSpinner.scss';

function AlfiSpinner({ size }) {
  const iconSize = size ? size : 5;

  return (
    <div
      className='logo-spinner'
      style={{ width: 7 * iconSize + 'px', height: 5 * iconSize + 'px' }}
      aria-label='loading...'
    >
      <div className='logo-1' />
      <div className='logo-2' />
      <div className='logo-3' />
      <div className='logo-4' />
      <div className='logo-5' />
      <div className='logo-6' />
    </div>
  );
}

export default AlfiSpinner;
