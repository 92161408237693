import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface QueryResponse<T> {
  loading: boolean;
  data?: T;
  error: boolean;
}

export const useQuery = <T = any>(
  endpoint: string,
  params: Record<any, any> = {},
  options?: {
    baseUrl: string;
  }
): QueryResponse<T> => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<boolean>(false);

  const { env } = useEnvironmentProvider();

  const fetchApi = async () => {
    try {
      setLoading(true);

      const url = `${options?.baseUrl || env?.REACT_APP_BASE_URL}/${endpoint}`;

      const response = await axios.post<T>(url, params);

      const { data: responseData, status } = response;

      if (status !== 200) {
        setLoading(false);

        setError(true);

        return;
      }

      setData(responseData);
    } catch (err) {
      console.error(err);

      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (options?.baseUrl || env?.REACT_APP_BASE_URL) {
      fetchApi();
    }
  }, [options?.baseUrl || env?.REACT_APP_BASE_URL]);

  return { loading, data, error };
};
