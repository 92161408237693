import { UserSelfResponse, UsersViewResponse } from 'app/common/types';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface UserConfig {
  skipDriverOnboarding?: boolean;
}

export type UserAuthState = Partial<UserSelfResponse> & UserConfig;

interface AuthState {
  user: UserAuthState;
  legacyUser: Partial<UsersViewResponse>;
}

const initialState: AuthState = {
  user: {},
  legacyUser: {},
};

export const useAuthStore = create<AuthState>()(
  persist(
    devtools(() => initialState),
    { name: 'auth' }
  )
);

// should be used when setting many values at once
export const dispatchUpdateAuth = (authState: Partial<AuthState>) =>
  useAuthStore.setState(() => ({
    ...authState,
  }));

export const dispatchUpdateUser = (user: Partial<UserAuthState>) =>
  useAuthStore.setState(() => ({
    user: {
      ...useAuthStore.getState().user,
      ...user,
    },
  }));

export const dispatchLogout = () => useAuthStore.setState(() => initialState);
