import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';

import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { Input } from 'app/common/shared/input/input';
import { Text } from 'app/common/shared/text/text';
import Checkbox from 'app/common/shared/checkbox/checkbox';
import { CalendarIcon } from 'app/common/shared/icons/icons';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import { toast } from 'react-toastify';
import { dispatchUpdateUser } from 'app/pages/authentication/use-auth-store';
import { ActionFooter } from '../drivers/driver-onboarding';
import { stepsMapping } from './onboarding';

export function DriverContract() {
  const { t } = useTranslation();
  const { env } = useEnvironmentProvider();

  const [checked, setChecked] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep } = useWizardActions(wizardKeys.ONBOARDING);

  const submitContract = handleSubmit(async (data) => {
    try {
      const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
        jwt_token: string;
      };

      const response = await axios.patch(
        `${env?.REACT_APP_BASE_URL}/nextgen/users/SignContract`,
        {
          nameOnContractSigning: data.name,
          dateOfContractSigning: new Date().toISOString(),
          contractSigningAgreed: true,
        },
        { headers: { Authorization: `Bearer ${jwt_token}` } }
      );
      if (response.status === 200) {
        toast.success(t('signing-success'));
        dispatchUpdateUser(response.data);
      }

      dispatchSetCurrentStep((currentStep + 1) as number);
    } catch (error) {
      toast.error(t('signing-fail'));
      console.log(error);
    }
  });

  const driverTerms = t('driver-terms-list').split('\n');
  const alfiTerms = t('alfi-terms-list').split('\n');
  const transations = t('transactions-list').split('\n');
  const data = t('data-list').split('\n');
  const term = t('term-list').split('\n');
  const general = t('general-list').split('\n');

  return (
    <div className='bg-gray-light p-6 flex flex-col pb-96'>
      <div className='px-6 py-4 bg-white'>
        <Text variant='h5SemiBold'>
          <h2 className='m-0 mb-4'>Alfi Driver Agreement</h2>
        </Text>
        <Text>
          <p>{t('agreement_p1')}</p>
        </Text>
        <Text>
          <p>{t('agreement_p2')}</p>
        </Text>
        <Text>
          <p>{t('agreement_p3')}</p>
        </Text>
        <div>
          <Text variant='h5SemiBold'>
            <h3>I . {t('agreement_h1')}</h3>
          </Text>
          <Text>
            <ol>
              {driverTerms.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </Text>
          <Text variant='h5SemiBold'>
            <h3>II. {t('agreement_h2')}</h3>
          </Text>
          <Text>
            <ol>
              {alfiTerms.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </Text>
          <Text variant='h5SemiBold'>
            <h3>III. {t('agreement_h3')}</h3>
          </Text>
          <Text>
            <ol>
              {transations.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </Text>
          <Text variant='h5SemiBold'>
            <h3>IV. {t('agreement_h4')}</h3>
          </Text>
          <Text>
            <ol>
              {data.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </Text>
          <Text variant='h5SemiBold'>
            <h3>V. {t('agreement_h5')}</h3>
          </Text>
          <Text>
            <ol>
              {term.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </Text>
          <Text variant='h5SemiBold'>
            <h3>VI. {t('agreement_h6')}</h3>
          </Text>
          <Text>
            <ol>
              {general.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ol>
          </Text>
        </div>
      </div>
      <ActionFooter className='self-center bg-gray-light pb-6'>
        <form className='flex flex-col h-full grow' onSubmit={submitContract}>
          <div className='flex flex-col bg-white p-6 mb-6'>
            <Checkbox
              isChecked={checked}
              className='mb-6'
              onChange={(event) => {
                console.log(event);
                setChecked(!checked);
              }}
              name='agree'
              label={'I agree with the terms and conditions'}
            />
            <Input
              label='Date'
              className='mb-6'
              icon={<CalendarIcon />}
              defaultValue={format(new Date(), 'MM/dd/yyyy')}
              disabled
              {...register('date')}
            />
            <Input
              label='Full Name'
              {...register('name', { required: true })}
            />
          </div>
          <button
            disabled={!isValid || !checked}
            type='submit'
            className='bg-button-gradient rounded text-white p-4 border-0 disabled:bg-gray-medium-light disabled:text-gray-medium disabled:bg-none disabled:cursor-not-allowed disabled:shadow-none hover:shadow-md active:shadow-none transition-shadow'
          >
            <Text variant='bodyMedium'>
              <span>{t('send')}</span>
            </Text>
          </button>
        </form>
      </ActionFooter>
    </div>
  );
}
