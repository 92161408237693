import React, { useState, useEffect } from 'react';
import emailValidator from 'email-validator';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Phone from '@material-ui/icons/Phone';

import { AlfiLogo } from 'app/common/shared/icons/icons';
import { Input, PasswordInput } from 'app/common/shared/input/input';
import { Text } from 'app/common/shared/text/text';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { useAuth } from '../authentication/auth-provider';

import { PhoneInput } from 'app/common/shared/phone-input/phone-input';

import './styles.scss';
import { useTenantProvider } from 'providers/tenant-provider';
import { FindTenantResponse } from 'app/common/types';
import { Link, useNavigate } from 'react-router-dom';

interface LoginFormValues {
  identifier?: string;
  password: string;
  maskedPhoneNumber?: string;
  phoneNumberWithCountryCode?: string;
}

const isolatedAxios = axios.create();

export const LoginScreen = () => {
  const { handleLogin } = useAuth();
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const { env } = useEnvironmentProvider();
  const {
    register,
    formState: { isValid },
    handleSubmit,
    setValue,
    watch,
    unregister,
  } = useForm<LoginFormValues>({
    mode: 'onChange',
  });

  const { setTenant } = useTenantProvider();

  const navigate = useNavigate();

  const getTenant = async (identifier: string, isEmail: boolean) => {
    const endpoint = isEmail
      ? '/users/findtenants'
      : '/users/findtenantbyphonenumber';

    const url = `${env?.REACT_APP_BASE_URL}${endpoint}`;

    const request = isEmail
      ? isolatedAxios.post<FindTenantResponse<typeof isEmail>>(url, {
          email: identifier,
        })
      : isolatedAxios.get<FindTenantResponse<typeof isEmail>>(url, {
          params: {
            PhoneNumber: identifier,
          },
        });

    return await request;
  };

  const onSubmit = handleSubmit(async (data) => {
    const identifier = isEmailLogin
      ? data.identifier?.trim()
      : data.phoneNumberWithCountryCode;

    if (!identifier) {
      return;
    }

    const isEmail = emailValidator.validate(identifier);

    try {
      const getTenantResponse = await getTenant(identifier, isEmail);

      const payload = isEmail
        ? { email: identifier }
        : { phoneNumber: identifier };

      const tenant = getTenantResponse.data.tenants?.[0];

      if (!tenant) {
        toast.error(`${isEmail ? 'Email' : 'Phone number'} not registered`);

        return;
      }

      setTenant({
        ...tenant,
        // @ts-ignore
        shortname: tenant.shortname || tenant.shortName,
      });

      await handleLogin({
        ...payload,
        password: data.password,
        tenant_id: getTenantResponse.data.tenants[0].id,
        nextgen: true,
      });
    } catch (error) {
      console.error(error);

      toast.error('Something went wrong');
    }
  });

  useEffect(() => {
    register('phoneNumberWithCountryCode', { required: !isEmailLogin });
    return () => {
      // unregister fields not needed for the type of login
      if (!isEmailLogin) {
        unregister('phoneNumberWithCountryCode');
        unregister('maskedPhoneNumber');
        return;
      }

      unregister('identifier');
    };
  }, [register, isEmailLogin]);

  return (
    <>
      <form
        className='flex flex-col max-w-full pt-8 px-6 md:pt-[10%]'
        onSubmit={onSubmit}
      >
        <AlfiLogo className='h-11 w-40 self-center mb-8' />

        {isEmailLogin ? (
          <Input
            label='Enter Email Address'
            type='email'
            className='mb-6'
            {...register('identifier', { required: isEmailLogin })}
          />
        ) : (
          <div className='mb-6 flex flex-col'>
            <PhoneInput
              label='Enter Phone Number'
              inputProps={{
                autoFocus: true,
                type: 'tel',
                ...register('maskedPhoneNumber', {
                  required: !isEmailLogin,
                  validate: () => isPhoneValid,
                }),
                value: watch('maskedPhoneNumber'),
              }}
              onCountryCodeChange={() => {
                setValue('maskedPhoneNumber', '');
              }}
              onAccept={({ maskedValue, selectedValue, unmaskedValue }) => {
                const shouldValidateMask = !!selectedValue.format;

                setIsPhoneValid(
                  shouldValidateMask
                    ? `${maskedValue.replace('_', '')}` === maskedValue
                    : true
                );

                setValue('maskedPhoneNumber', maskedValue, {
                  shouldValidate: true,
                });

                setValue(
                  'phoneNumberWithCountryCode',
                  `+${selectedValue.dialing_code}${unmaskedValue}`,
                  {
                    shouldValidate: true,
                  }
                );
              }}
            />
          </div>
        )}

        <PasswordInput
          label='Password'
          className='mb-8'
          {...register('password', { required: true })}
        />
        {/*TODO: refactor existing shared button component to use Tailwind classes*/}
        <button
          disabled={!isValid}
          type='submit'
          className='bg-button-gradient rounded text-white p-4 border-0 disabled:bg-gray-medium-light disabled:text-gray-medium disabled:bg-none disabled:cursor-not-allowed disabled:shadow-none hover:shadow-md active:shadow-none transition-shadow'
        >
          <Text variant='bodyMedium'>
            <span>Log in</span>
          </Text>
        </button>
        <button
          type='button'
          className='m-0 text-primary mt-8 text-center cursor-pointer bg-transparent border-0 self-center'
          onClick={() => setIsEmailLogin(!isEmailLogin)}
        >
          <Text variant='h5SemiBold'>
            <span>Log in with {isEmailLogin ? 'phone number' : 'email'}</span>
          </Text>
        </button>
        <Link
          to='/forgot-password'
          className='m-0 text-primary mt-4 text-center self-center no-underline'
        >
          <Text variant='h5SemiBold'>
            <span>Forgot password?</span>
          </Text>
        </Link>
      </form>
      <div className='nest self-center mt-auto flex resticky bottom-0'>
        <Phone className='phone' />
        <a href='tel:+1-305-395-4520 ' className='number'>
          +1 (305) 395-4520 - Support
        </a>
      </div>
    </>
  );
};

export const Authentication = () => {
  return (
    <div className='flex flex-col h-full relative md:mx-auto md:w-3/4 md:max-w-screen-sm'>
      <LoginScreen />
    </div>
  );
};
