import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface OverlayState {
  show: boolean;
  content: React.ReactNode;
  showOverlay: ({ show, content }) => void;
}

export const useOverlay = create<OverlayState>()(
  devtools((set) => ({
    show: false,
    content: null,
    showOverlay: ({ show, content }) => set((state) => ({ content, show })),
  }))
);
