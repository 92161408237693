import React from 'react';
import clsx from 'clsx';
import './button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'outline' | 'filled';
}

export function Button({ children, variant, className, ...rest }: ButtonProps) {
  return (
    <button className={clsx(`btn btn-${variant}`, className)} {...rest}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  variant: 'filled',
};
