import React, { useState, Suspense, lazy } from 'react';
import clsx from 'clsx';
import '../../../styles/scss/style.scss';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import { MainListItems } from './main-list-items';
// import AdvertisersDropdown from '../campaign-management/advertisers/advertisers-dropdown';
import { useAuth } from '../authentication/auth-provider';
import { Breadcrumb } from './breadcrumb';
import AlfiSpinner from 'app/common/shared/Spinners/AlfiSpinner/AlfiSpinner';
import { Main } from './main';
import { useLocation } from 'app/common/hooks/use-location';

const alfiLogo =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjAAAABaCAMAAACR35xIAAAAkFBMVEX///8dHRsQEA0AAAB4eHcUFBJVVVTv7+7y8vLjBhMGBgCjo6NPT06srKuTk5Jra2rp6egoKCe9vbxycnJkZGMYGBb86enrVl7iAAblAA/JMDY4Jyfc3Nw7OzrLy8osLCqOjo2AgH+zs7NDQ0LcaG6DeXkxHh5aWliHh4anp6bU1NQzMzFCQkHFDxodAAAjIyHas9yDAAAERElEQVR4nO3dbVvaMBiG4bah0LUwW2j34gQBt6G4rf//360F1JGwEmzy5IX7/CLC0DRcyxG3qMFn3vcfAyqzLDhvOuOfNZV4FqFkSTZhIrkpLDmbyckL+Xj8oddBsmreLkeHx9t3BsEX3u3PiAorJa42Y/yzPkq/liTyMT9CQmwmMcJJGh87PYWj4dGFsGWQh+3bxf7hxe7e4APn6+23kEp0I3G1GeOeZV8wMdmMCSKpYCLuWenpYDYx94fyonr7FKu0vRfB9OZAMPwA/xPMmLWax6vdjcd9MGGa7C5z3t5GMP15E0wwyvN8NGVhdZ+3kkMwbLdPmuxfBwTTmz/B7CzaYF4uraiixzQetrfn8X1ZIBgFHAhGbg+zxwXDFmFVN9venMXDLEIwCjgQTI8Vhj1lrPl6KVimLFszBKOA78HcVfFDkpQRyzMEo4LfwTSv0Sxi220dlbsvrBFMbw4E02MP07xGkyhdTRlbBwPsYVRwIJgeK0z8ECRF/FxGv+6S9h/2EExvfgfT3p5FVZyugmSIYFTwPJjn3V0hWyAYRfwPJhmyKAwQjCIOBNNj09sGE6w35ROCUcWBYHquMAf7YITzML//MF2Eq/U0mErbDIpkjhRdGEwVvwZTV6/xtMFEVRV8EiS6rPmX3tNgquJO2xyKJEZ4WTBpUb+sKvlzXc9fH0k2dVH0mqoLTa8mmNH5Z1G6aA+TLBov7zQ3t28Pbf95hAKCMeSiFcYiCMYQBGMVBKMLgjHkoj2MRRCMIVhhrIJgdEEwhiAYqyAYXRCMIdj0WsWBYLDC2ATB6IJgDEEwVnEgGOxhbOJAMFhhbHJlwYzIXNMBKsJZlRih0mCGdB6q6wgmDMdkczpeSoxQ6R4mpsP34m8wFdmcyv2MO5UrDH+tlLwNho767xo4x8hlHiCY3hCMAMF0Uf+NbOcYucwDBNMbVhgBgumCYAQIpguCESCYLghGgGC6YNMrQDBdsMIIEEwXBCNAMF0QjADBdMEeRoBgumCFESCYLjYEo+3/5nG8QT0LjjfU2k7/4ACVevQHqIS/HHNd5wtxRFPLrEqMUOsKU821nWDGIXBDEIxVEIy6kR5DMIYgGKs4EIzeTS+CuYwDwWCFsQmCUTfSYwjGEARjFQeCwR7GJg4EgxXGJghG3UiPIRhDEIxVHAgGexibOBAMVhibIBh1Iz2GYAxBMFZxIBjNB6h0/TpLRQeo0qWuAYpkXnkHgtF6RFPj79DlP9X7gglTfSMU3J0f4LUHQ+idwRBCMAKyuT8BwdBAMGQ8Cea6v/ORkifBYIWhgmAEZHN/AoKhgWDIeBIM9jBUPAkGKwwVBCMgm/sTEAwNBEPGk2Cwh6HiSTBYYaggGAHZ3J+AYGggGDIIRhAZxEqJAWbM5AilguFHGNoWTMr/ZLwewQwMmmUSA5zODI7wJj8/wGTJP2sl83PECE1Lzmby7o/1F4jN5B91O1PYAAAAAElFTkSuQmCC';

const drawerWidth = 244;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  navbarLogo: {
    maxWidth: 'calc(100% - 4rem)', // 100% of the space within the container minus the approx. size of the hide menu button
    maxHeight: '100%',
  },
}));

interface AdminLayoutProps {
  children: React.ReactNode;
}

export function AdminLayout({ children }: AdminLayoutProps) {
  const classes = useStyles();
  const { logout } = useAuth();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    // Clear user from context and clear token
    logout();
  };

  const location = useLocation<any>();

  const titles = {
    advertisers: 'Advertisers',
    campaigns: 'Campaigns',
    creatives: 'Creatives',
    flights: 'Flights',
  };

  const getTitle = () => {
    const paths = location?.pathname?.split('/');
    const pathName = paths[paths.length - 1];
    if (!pathName || !titles[`${pathName}`]) return '';
    return titles[`${pathName}`];
  };

  const getListItems = () => {
    const MainListItems = lazy(() =>
      import('./main-list-items').then((module) => ({
        default: module.MainListItems,
      }))
    );
    return <MainListItems />;
  };

  return (
    <div className='dashboard'>
      <CssBaseline />
      <AppBar
        position='absolute'
        className={clsx(
          classes.appBar,
          open && classes.appBarShift,
          'dashboard__header'
        )}
      >
        <Toolbar className={classes.toolbar}>
          <Tooltip id='tooltip-open-menu' title='Open menu' placement='top'>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open menu'
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}
          >
            <Breadcrumb />
          </Typography>
          {/* <AdvertisersDropdown /> */}
          <Tooltip id='tooltip-top' title='Logout' placement='top'>
            <IconButton onClick={handleLogout} color='inherit'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                style={{ fill: '#fff' }}
              >
                <path d='M19,3 C20.11,3 21,3.9 21,5 L21,8 L19,8 L19,5 L5,5 L5,19 L19,19 L19,16 L21,16 L21,19 C21,20.1 20.11,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 L19,3 Z M15.5,17 L20.5,12 L15.5,7 L14.09,8.41 L16.67,11 L7,11 L7,13 L16.67,13 L14.09,15.59 L15.5,17 Z' />
              </svg>
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        className='dashboard__drawer'
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img
            alt='ALFI Web Portal'
            src={alfiLogo}
            className={classes.navbarLogo}
          />
          <Tooltip id='tooltip-hide-menu' title='Hide menu' placement='top'>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AlfiSpinner size={30} />
            </div>
          }
        >
          {getListItems()}
        </Suspense>
      </Drawer>
      <Main>
        <div className='px-6'>{children}</div>
      </Main>
    </div>
  );
}
