import { Dialog, Transition } from '@headlessui/react';
import { CloseIcon, UploadIcon } from 'app/common/shared/icons/icons';
import {
  AndroidDeviceInstructions,
  IOSDeviceInstructions,
  LyftLogo,
  UberLogo,
} from 'app/common/shared/icons/illustrations';
import { Modal } from 'app/common/shared/modal/modal';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import axios from 'axios';
import prettyBytes from 'pretty-bytes';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import uberSampleImage from 'assets/uber-sample.png';
import lyftSampleImage from 'assets/lyft-sample.png';
import { toast } from 'react-toastify';
import { Footer } from './action-footer';
import { stepsMapping } from './onboarding';
import { StepperHeader } from './stepper-header';

const isolatedAxios = axios.create();

interface UserRidesScreenValues {
  lyftFile?: FileList;
  uberFile?: FileList;
}

const InstructionsDialogSteps = ({ title, illustration, steps }) => {
  const { t } = useTranslation();

  return (
    <div className='mb-10 last:mb-0'>
      <Text variant='h5SemiBold'>
        <p className='text-gray m-0 mb-4'>{title}</p>
      </Text>
      <Text variant='bodyMediumExtraSmall'>
        <p className='text-gray m-0 mb-8'>{t('important-instructions')}</p>
      </Text>
      {steps.map((step, index) => (
        <Text variant='bodyMediumExtraSmall'>
          <p className='text-gray m-0 mb-4 last-of-type:mb-6'>
            <Text variant='bodyExtraBoldSmall'>
              <span className='text-gray mr-3'>Step {index + 1}</span>
            </Text>
            {step}
          </p>
        </Text>
      ))}
      {illustration}
    </div>
  );
};

const InstructionsDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      content={
        <div className='p-6'>
          <Text variant='h5ExtraBold'>
            <Dialog.Title as='h5' className='text-dark m-0 mb-9'>
              {t('how-screenshot')}
            </Dialog.Title>
          </Text>
          <InstructionsDialogSteps
            illustration={<IOSDeviceInstructions />}
            title='iOS Devices'
            steps={['Press power button (A)', 'Press volume up button (B)']}
          />
          <InstructionsDialogSteps
            illustration={<AndroidDeviceInstructions />}
            title='Android Devices'
            steps={['Press power button (A)', 'Press volume down button (B)']}
          />
        </div>
      }
    />
  );
};

interface FileUploadZoneProps {
  inputProps: React.ComponentProps<'input'>;
  onRemoveFile: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  fileMetadata?: {
    filename?: string;
    fileSize?: number;
    filePreview?: string;
  };
}

const FileUploadZone = ({
  onRemoveFile,
  fileMetadata,
  inputProps,
  label,
}: FileUploadZoneProps) => {
  return (
    <div className='relative'>
      <label
        htmlFor={inputProps.name}
        className='min-h-72 w-full bg-gray-light outline-dashed outline-1 outline-grim-purple rounded-lg flex items-center justify-center'
      >
        {fileMetadata?.filePreview ? (
          <>
            <img
              className='object-contain h-full max-h-96 md:max-h-[50vh] max-w-full'
              alt='preview'
              src={fileMetadata?.filePreview}
            />
          </>
        ) : (
          <div className='flex text-grim-purple justify-center items-center flex-wrap px-6 text-center gap-4'>
            <UploadIcon />
            <Text variant='h5Regular'>
              <h5
                className=' m-0'
                dangerouslySetInnerHTML={{ __html: label }}
              />
            </Text>
          </div>
        )}
        <input
          {...inputProps}
          id={inputProps.name}
          type='file'
          accept='image/*'
          className='hidden'
          onClick={(e) => {
            e.currentTarget.value = '';
          }}
        />
      </label>
      {fileMetadata?.filePreview && (
        <div className='flex mt-6'>
          <div>
            <Text variant='bodyMedium'>
              <p className='m-0 mb-2'>{fileMetadata.filename}</p>
            </Text>
            <Text variant='bodyMedium'>
              <p className='m-0 text-gray-medium'>
                {prettyBytes(fileMetadata.fileSize || 0)}
              </p>
            </Text>
          </div>
          <div>
            <button
              className='border-none rounded-full p-2 right-7 bottom-7 bg-gray-light/20 leading-[0px]'
              type='button'
              onClick={onRemoveFile}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export const UserRidesScreen = () => {
  const { t } = useTranslation();

  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);

  const [previewImages, setPreviewImages] =
    React.useState<{ uberFile?: string; lyftFile?: string }>();

  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const [previewSample, setPreviewSample] = React.useState<
    string | undefined
  >();

  const onPreviewSampleClose = () => {
    setPreviewSample(undefined);
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<UserRidesScreenValues>({
    mode: 'onChange',
  });

  const { env } = useEnvironmentProvider();

  const onSubmit = handleSubmit(async (data) => {
    const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
      jwt_token: string;
    };

    const formData = new FormData();

    if (data.uberFile?.[0]) {
      formData.append('uber', data.uberFile[0]);
    }

    if (data.lyftFile?.[0]) {
      formData.append('lyft', data.lyftFile[0]);
    }

    try {
      await isolatedAxios.post(
        `${env?.REACT_APP_BASE_URL}/nextgen/Users/UploadScreenshot`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );

      dispatchSetStepData(currentStep, data);

      dispatchSetCurrentStep((currentStep + 1) as number);
    } catch (error) {
      console.error(error);

      toast.error('Failed to upload screenshot');
    }
  });

  const onFileChange = (name: string, formFile?: FileList) => {
    const file = formFile?.[0];

    if (!file || (file.type && !file.type.startsWith('image/'))) {
      console.warn('File is not an image');

      return;
    }

    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      const src = event.target?.result;

      setPreviewImages((prev) => ({
        ...prev,
        [name]: src as string,
      }));
    });

    reader.readAsDataURL(file);
  };

  const uberFile = watch('uberFile');

  const lyftFile = watch('lyftFile');

  useEffect(() => {
    onFileChange('uberFile', uberFile);
  }, [uberFile]);

  useEffect(() => {
    onFileChange('lyftFile', lyftFile);
  }, [lyftFile]);

  return (
    <>
      <StepperHeader step={3} totalSteps={3} title={t('number_of_rides')} />
      <form
        className='py-8 px-6 gap-8 flex h-full flex-col'
        onSubmit={onSubmit}
      >
        <Text>
          <h5 className='m-0'>{t('number_of_rides_upload_screenshot')} </h5>
        </Text>
        <div className='flex gap-6'>
          <div className='flex flex-col grow basis-1/2'>
            <UberLogo className='h-7 mb-4' />
            {!uberFile?.length && (
              <button
                className='border-none p-0 mb-6 leading-0 bg-transparent w-fit'
                type='button'
                onClick={() => {
                  setPreviewSample(uberSampleImage);
                }}
              >
                <Text variant='h5SemiBold'>
                  <span className='m-0 text-primary'>See screen example</span>
                </Text>
              </button>
            )}
            <FileUploadZone
              inputProps={register('uberFile', { required: !lyftFile?.length })}
              onRemoveFile={(e) => {
                e.stopPropagation();
                e.preventDefault();

                setPreviewImages((prev) => ({
                  ...prev,
                  uberFile: undefined,
                }));

                setValue('uberFile', undefined, { shouldValidate: true });
              }}
              fileMetadata={{
                filename: uberFile?.[0]?.name,
                fileSize: uberFile?.[0]?.size,
                filePreview: previewImages?.uberFile,
              }}
              label={t('onboarding.driver_verification.upload_uber_screenshot')}
            />
          </div>
          <div className='flex flex-col grow basis-1/2'>
            <LyftLogo className='h-7 mb-4' />
            {!lyftFile?.length && (
              <button
                className='border-none p-0 mb-6 leading-0 bg-transparent w-fit'
                type='button'
                onClick={() => {
                  setPreviewSample(lyftSampleImage);
                }}
              >
                <Text variant='h5SemiBold'>
                  <span className='m-0 text-primary'>See screen example</span>
                </Text>
              </button>
            )}
            <FileUploadZone
              inputProps={register('lyftFile', { required: !uberFile?.length })}
              onRemoveFile={(e) => {
                e.stopPropagation();
                e.preventDefault();

                setPreviewImages((prev) => ({
                  ...prev,
                  lyftFile: undefined,
                }));

                setValue('lyftFile', undefined, { shouldValidate: true });
              }}
              fileMetadata={{
                filename: lyftFile?.[0]?.name,
                fileSize: lyftFile?.[0]?.size,
                filePreview: previewImages?.lyftFile,
              }}
              label={t('onboarding.driver_verification.upload_lyft_screenshot')}
            />
          </div>
        </div>
        <button
          className='border-none leading-0 bg-transparent w-fit self-center'
          onClick={() => setIsInstructionsModalOpen(true)}
          type='button'
        >
          <Text variant='h5SemiBold'>
            <h5 className='m-0 text-primary text-center'>
              {t('onboarding.driver_verification.driver_verification_p')}
            </h5>
          </Text>
        </button>
        <Footer disabled={!isValid} />
      </form>
      <InstructionsDialog
        isOpen={isInstructionsModalOpen}
        onClose={() => setIsInstructionsModalOpen(false)}
      />
      <Modal
        isOpen={!!previewSample}
        onClose={onPreviewSampleClose}
        content={
          <div className='h-full w-full max-h-[70vh] flex items-center justify-center'>
            <img src={previewSample} className='object-contain' />
          </div>
        }
      />
    </>
  );
};
