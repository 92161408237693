import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import { AuthProvider } from './app/pages/authentication/auth-provider';
import { TokenProvider } from 'providers/TokenProvider';
import { EnvironmentProvider } from 'providers/EnvironmentProvider';
import { TenantProvider } from 'providers/tenant-provider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './styles/css/index.css';
// language support
import './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <EnvironmentProvider>
        <TokenProvider>
          <AuthProvider>
            <TenantProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </TenantProvider>
          </AuthProvider>
        </TokenProvider>
      </EnvironmentProvider>
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
