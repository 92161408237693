import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShippingIllustration } from 'app/common/shared/icons/illustrations';
import { Button } from 'app/common/shared/button/button';
import { Text } from 'app/common/shared/text/text';
import { ArrowReturnIcon } from 'app/common/shared/icons/icons';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';

import { useAuthStore } from '../authentication/use-auth-store';
import { stepsMapping } from './onboarding';
import axios from 'axios';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { UserSelfResponse, GoSelfResponse } from 'app/common/types';
import { translateUserSelfResponse } from '../authentication/auth-provider';

const isolatedAxios = axios.create();

export function DriverConfirmAddress() {
  const { t } = useTranslation();

  const { stepsData } = useWizardStore((state) => state[wizardKeys.ONBOARDING]);

  const stepData: {
    name: string;
    city: string;
    state: string;
    postalCode: string;
    street: string;
    streetLine2: string;
  } = (stepsData[stepsMapping.DRIVER_CONFIRM_ADDRESS] as any) || {};

  const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
    jwt_token: string;
  };

  const { env } = useEnvironmentProvider();

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const onConfirm = async () => {
    await isolatedAxios.patch(
      `${env?.REACT_APP_BASE_URL}/nextgen/Users/ConfirmShippingAddress`,
      {},
      {
        headers: { Authorization: `Bearer ${jwt_token}` },
      }
    );

    dispatchSetCurrentStep(stepsMapping.THANK_YOU);
  };

  React.useEffect(() => {
    if (!stepData?.name) {
      isolatedAxios
        .post<GoSelfResponse>(
          `${env?.REACT_APP_BASE_URL}/users/self`,
          {},
          {
            headers: {
              Authorization: `Bearer ${jwt_token}`,
              'Content-type': 'application/json',
            },
          }
        )
        .then((response) => {
          const self = translateUserSelfResponse(response.data);
          dispatchSetStepData(stepsMapping.DRIVER_CONFIRM_ADDRESS, {
            name: self.firstName,
            city: self.shippingCity || self.city,
            state: self.shippingStateRegion || self.stateRegion,
            postalCode: self.shippingPostalCode || self.postalCode,
            street: self.shippingStreet || self.street,
            streetLine2: self.shippingStreetLine2 || self.streetLine2,
          });
        });
    }
  }, [stepData?.name]);

  return (
    <div className='flex flex-col items-center h-full pt-10 px-6'>
      <ShippingIllustration className='flex-shrink-0' />
      <Text variant='h5Regular'>
        <h5 className='m-0 mb-8 text-center'>
          {t('driver_onboarding.driver_confirm_address.congratulations')}{' '}
          {stepData.name}!
          <br /> {t('driver_onboarding.driver_confirm_address.p1')}
        </h5>
      </Text>
      <div className='bg-gray-light py-8 w-full mb-6 rounded-md'>
        <Text variant='h4Bold'>
          <h4 className='m-0 mb-4 text-center'>
            {stepData.street} <br /> {stepData.streetLine2}
          </h4>
        </Text>
        <Text variant='h4SemiBold'>
          <h4 className='m-0 text-center'>
            {[stepData.city, stepData.state, stepData.postalCode].join(', ')}
          </h4>
        </Text>
      </div>
      <button
        className='border-none leading-0 bg-transparent w-fit self-center'
        onClick={() =>
          dispatchSetCurrentStep(stepsMapping.DRIVER_UPDATE_ADDRESS)
        }
      >
        <Text variant='bodyMedium'>
          <h4 className='m-0 text-center text-primary'>
            <ArrowReturnIcon className='mr-2' />
            {t(
              'driver_onboarding.driver_confirm_address.change_shipping_address'
            )}
          </h4>
        </Text>
      </button>
      <div className='w-full mt-auto flex sticky bottom-0 z-10 pb-6 pt-1 self-center'>
        <Button type='button' className='w-full py-4' onClick={onConfirm}>
          {t(
            'driver_onboarding.driver_confirm_address.confirm_shipping_address'
          )}
        </Button>
      </div>
    </div>
  );
}
