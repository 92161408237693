import type { UserStatus } from './types';

export const roles = {
  SUPERADMIN: 'superadmin',
  OPSADMIN: 'opsadmin',
  DRIVER: 'driver',
  KIOSKOWNER: 'kioskowner',
  TENANTADMIN: 'tenantadmin',
} as const;

export const userStatuses: UserStatus = {
  READY_TO_VERIFY: 'ready_to_verify',
  READY_FOR_INFO: 'ready_for_info',
  READY_FOR_DRIVER_VERIFICATION: 'ready_for_driver_verification',
  READY_FOR_APPROVAL: 'ready_for_approval',
  NOT_APPROVED: 'not_approved',
  READY_FOR_CONTRACT: 'ready_for_contract',
  READY_FOR_ADDRESS_CONFIRMATION: 'ready_for_address_confirmation',
  READY_TO_SHIP: 'ready_to_ship',
  READY_TO_ACTIVATE: 'ready_to_activate',
  ONBOARDING_COMPLETE: 'onboarding_complete',
} as const;

export const ESPER_TOKEN = 'RDF2xksxiibwI66Tx0pjfQunDwmQ1p';
export const ESPER_ENTERPRISE_ID = '794dffc7-cbb0-4660-b2fd-a2ab734b97db';
export const ESPER_BASE_URL = 'https://getalfi-api.esper.cloud/api/';
export const ESPER_LOCATION_BASE = `https://getalfi-api.esper.cloud/api/enterprise/${ESPER_ENTERPRISE_ID}/device/`;
