import React, { forwardRef, Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { IMaskInput } from 'react-imask';
import { ChevronIcon, NetworkIcon, SearchIcon } from '../icons/icons';
import { Input } from '../input/input';
import { useQuery } from 'app/common/hooks/use-query';

interface Country {
  id: number;
  dialing_code: string;
  iso_alpha3: string;
  name: string;
  format: string;
}

interface PhoneInputProps {
  onAccept?: (values: {
    selectedValue: Country;
    unmaskedValue: string;
    maskedValue: string;
  }) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onCountryCodeChange?: () => void;
  label?: string;
}

const getMask = (format) => {
  if (!format) {
    return '';
  }

  let mask = '';

  const digits = format.split(',');

  digits.forEach((digit) => {
    if (mask) {
      mask += '-';
    }

    mask += '0'.repeat(Number(digit));
  });

  return mask;
};

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (props: PhoneInputProps, ref) => {
    const { data } = useQuery('countries');

    const [selectedValue, setSelectedValue] = useState({
      id: 1,
      dialing_code: '1',
      iso_alpha3: 'USA',
      name: 'United States',
      format: '3,3,4',
    });

    const [query, setQuery] = useState('');

    const filteredCountries =
      (query === ''
        ? data?.countries
        : data?.countries?.filter((country) =>
            country.name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, ''))
          )) || [];

    // for optional character use '[0]'
    const mask = getMask(selectedValue.format);

    return (
      <Combobox
        value={selectedValue}
        onChange={(value) => {
          setSelectedValue(value);

          props.onCountryCodeChange?.();
        }}
      >
        <Combobox.Label
          htmlFor={props.inputProps?.name}
          className='font-redhat text-base font-medium text-dark mb-1'
        >
          {props.label ?? 'Phone Number'}
        </Combobox.Label>
        <div className='relative mt-1 font-redhat z-30'>
          <div>
            <Combobox.Button className='text-dark absolute border-solid bg-white inset-y-0 left-0 outline-none border border-y-gray-medium-light border-r-0 border-l-gray-medium-light rounded-l-[4px] flex items-center pl-[10px]'>
              <NetworkIcon className='h-5 w-5 mr-1' aria-hidden='true' />
              <ChevronIcon className='h-5 w-5 mb-1' aria-hidden='true' />
            </Combobox.Button>
            <IMaskInput
              mask={`+${selectedValue?.dialing_code} ${
                mask || '[000000000000000]'
              }`}
              lazy={false}
              className='font-redhat w-full h-11 outline-none border border-solid border-gray-medium-light pl-16 rounded-[4px] py-2 pr-10 font-medium text-base text-gray'
              onAccept={(maskedValue, currentMask) => {
                props.onAccept?.({
                  selectedValue,
                  unmaskedValue: currentMask.unmaskedValue,
                  // maskedValue is the inputValue concatenated with the mask and placeholderChar
                  maskedValue: maskedValue as string,
                });
              }}
              placeholderChar='_'
              {...props.inputProps}
              id={props.inputProps?.name}
              value={props.inputProps?.value || `+`}
              ref={ref}
              onChange={() => {}}
            />
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className='absolute mt-1 max-h-64 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm p-0 pb-1'>
              <div className='relative h-full flex flex-col'>
                <div className='sticky top-0 z-20 shadow-sm'>
                  <Input
                    icon={<SearchIcon className='text-gray-medium' />}
                    placeholder='Search for countries'
                    className='border-0 placeholder:text-gray-medium h-11'
                    onChange={(event) => setQuery(event.target.value || '')}
                  />
                </div>
                {filteredCountries.length === 0 && query !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 font-medium text-base'>
                    Nothing found.
                  </div>
                ) : (
                  filteredCountries.map((country) => (
                    <Combobox.Option
                      key={country.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-3 list-none px-4 font-medium text-base text-gray ${
                          active ? 'bg-gray-light' : ''
                        }`
                      }
                      value={country}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-bold' : 'font-medium'
                            }`}
                          >
                            {country.name} (+{country.dialing_code})
                          </span>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </div>
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    );
  }
);
