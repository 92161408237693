import React, { forwardRef, useState } from 'react';
import clsx from 'clsx';
import './input.scss';
import { EyeIcon, EyeStrikeIcon, NetworkIcon } from '../icons/icons';
import { useTranslation } from 'react-i18next';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  icon?: React.ReactNode;
  showOptionalLabel?: boolean;
  bottomLabel?: React.ReactNode;
  hasError?: boolean;
  iconDirection?: 'left' | 'right';
}

const IconContainer = ({ children, icon }) => {
  if (!icon) {
    return children;
  }

  return (
    <div className='form-group-input--icon'>
      <span>{icon}</span>
      {children}
    </div>
  );
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const {
      label,
      icon,
      iconDirection,
      bottomLabel,
      showOptionalLabel,
      hasError,
      ...rest
    } = props;

    const { t } = useTranslation();

    if (!props.label) {
      return (
        <IconContainer icon={props.icon}>
          <input
            type='text'
            {...props}
            ref={ref}
            className={clsx(
              'form-input h-11',
              props.className,
              hasError && 'border-primary-hover'
            )}
          />
        </IconContainer>
      );
    }

    return (
      <div className={clsx('form-group-input', props.className)}>
        <label htmlFor={props.name} className='mb-1'>
          <>
            {props.label}{' '}
            {showOptionalLabel && (
              <span className='text-gray-medium ml-1'>({t('optional')})</span>
            )}
          </>
        </label>
        <IconContainer icon={props.icon}>
          <input
            type='text'
            {...rest}
            ref={ref}
            id={props.name}
            className={`form-input h-11 ${
              hasError ? 'border-primary-hover' : ''
            }`}
          />
        </IconContainer>
        {bottomLabel}
      </div>
    );
  }
);

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <Input
        {...props}
        ref={ref}
        icon={
          showPassword ? (
            <EyeIcon onClick={() => setShowPassword(!showPassword)} />
          ) : (
            <EyeStrikeIcon onClick={() => setShowPassword(!showPassword)} />
          )
        }
        type={showPassword ? 'text' : 'password'}
        className={`form-group-input--password ${props.className}`}
      />
    );
  }
);
