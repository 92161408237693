import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { stepsMapping } from './onboarding';
import type { PhoneNumberScreenValues } from './onboarding';
import { Input } from 'app/common/shared/input/input';
import { Text } from 'app/common/shared/text/text';
import {
  useWizardActions,
  useWizardStore,
  wizardKeys,
} from 'app/common/shared/wizard/use-wizard-store';
import { Footer } from './action-footer';
import { StepperHeader } from './stepper-header';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';

const isolatedAxios = axios.create();

export interface UserInformationScreenValues {
  salutation: string;
  name: string;
  middleName: string;
  lastName: string;
  suffix: string;
  emailAddress: string;
}

export const UserInformationScreen = () => {
  const { currentStep, stepsData } = useWizardStore(
    (state) => state[wizardKeys.ONBOARDING]
  );

  const { dispatchSetCurrentStep, dispatchSetStepData } = useWizardActions(
    wizardKeys.ONBOARDING
  );

  const { t } = useTranslation();
  const { env } = useEnvironmentProvider();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<UserInformationScreenValues>({
    mode: 'onChange',
    defaultValues:
      (stepsData[currentStep] as UserInformationScreenValues) || {},
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { jwt_token } = stepsData[stepsMapping.PASSWORD] as {
        jwt_token: string;
      };
      const { phoneNumberCodeId } = stepsData[
        stepsMapping.PHONE_NUMBER
      ] as PhoneNumberScreenValues;

      const response = await isolatedAxios.post(
        `${env?.REACT_APP_BASE_URL}/nextgen/Users/UpdatePersonalInformation`,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );

      //api currently returns 200 and no body
      //const parsed = await response.json();
      //dispatchSetStepData({ step: currentStep, data: { ...data, ...parsed.data } });
      dispatchSetStepData(currentStep, data);
      dispatchSetCurrentStep((currentStep + 1) as number);
    } catch (error: unknown) {
      console.error(error);

      toast.error(t('submit_error_message'));
    }
  });

  return (
    <>
      <StepperHeader
        step={1}
        totalSteps={3}
        title={t('personal_information')}
      />
      <form
        onSubmit={onSubmit}
        className='py-8 px-6 gap-6 flex h-full flex-col'
      >
        <Text>
          <p className='m-0'>{t('personal_information_p')}</p>
        </Text>
        <Input
          label={t('salutation')}
          showOptionalLabel
          {...register('salutation')}
        />
        <Input
          label={t('name') + ' *'}
          required
          {...register('name', { required: true })}
        />
        <Input
          label={t('middle_name')}
          showOptionalLabel
          {...register('middleName')}
        />
        <Input
          label={t('last_name') + ' *'}
          required
          {...register('lastName', { required: true })}
        />
        <Input label='Suffix' {...register('suffix')} showOptionalLabel />
        <Input
          label={t('email') + ' *'}
          required
          type='email'
          {...register('emailAddress', { required: true })}
        />
        <Footer disabled={!isValid} />
      </form>
    </>
  );
};
