import create from 'zustand';
import { devtools } from 'zustand/middleware';

export type WizardKeys = 'login' | 'onboarding' | 'driverOnboarding';

export const wizardKeys = {
  LOGIN: 'login',
  ONBOARDING: 'onboarding',
  DRIVER_ONBOARDING: 'driverOnboarding',
} as const;

type WizardStepState = {
  stepsData: Record<number, unknown>;
  currentStep: number;
};

type WizardStore = {
  [key in WizardKeys]: WizardStepState;
};

const initialState: WizardStore = {
  login: {
    stepsData: {},
    currentStep: 1,
  },
  onboarding: {
    stepsData: {},
    currentStep: 1,
  },
  driverOnboarding: {
    stepsData: {},
    currentStep: 1,
  },
};

export const useWizardStore = create<WizardStore>()(
  devtools(() => initialState)
);

const dispatchSetCurrentStep = (key: WizardKeys) => (step: number) =>
  useWizardStore.setState((state) => ({
    [key]: {
      ...state[key],
      currentStep: step,
    },
  }));

const dispatchSetStepData =
  (key: WizardKeys) =>
  <T>(step: number, data: T) =>
    useWizardStore.setState((state) => ({
      [key]: {
        ...state[key],
        stepsData: {
          ...state[key].stepsData,
          [step]: data,
        },
      },
    }));

const dispatchResetData = (key: WizardKeys) => () =>
  useWizardStore.setState(() => ({
    [key]: {
      stepsData: {},
      currentStep: 1,
    },
  }));

export const useWizardActions = (key: WizardKeys) => ({
  dispatchSetCurrentStep: dispatchSetCurrentStep(key),
  dispatchSetStepData: dispatchSetStepData(key),
  dispatchResetData: dispatchResetData(key),
});
