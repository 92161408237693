import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

interface TokenContextType {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
}

const storageToken = window.localStorage.getItem('jwt_token');

const initialContext = {
  token: storageToken ? storageToken : '',
  setToken: () => {},
};

const TokenContext = createContext<TokenContextType>(initialContext);

const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(initialContext.token);

  useEffect(() => {
    window.localStorage.setItem('jwt_token', token);
  }, [token]);

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
};

const useTokenProvider = () => {
  const { token, setToken } = useContext(TokenContext);
  return { token, setToken };
};

export { useTokenProvider, TokenProvider, TokenContext };
