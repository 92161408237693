import React, { Fragment } from 'react';
import { useAuth } from '../authentication/auth-provider';
import { AdminLayout } from './admin-layout';
import { SimpleLayout } from './simple-layout';
import { KioskLayout } from './kiosk-layout';
import AxiosConfig from 'app/common/axios-config';
import { Outlet } from 'react-router-dom';
import { roles } from 'app/common/constants';

interface LayoutProps {
  children?: React.ReactNode;
}

const layoutMapping = {
  [roles.DRIVER]: SimpleLayout,
  [roles.OPSADMIN]: SimpleLayout,
  [roles.KIOSKOWNER]: KioskLayout,
  [roles.SUPERADMIN]: AdminLayout,
};

export default function Layout({ children }: LayoutProps) {
  const { auth } = useAuth();

  const LayoutComponent = layoutMapping[auth.role] || Fragment;

  return (
    <LayoutComponent>
      <AxiosConfig />
      <>{children || <Outlet />}</>
    </LayoutComponent>
  );
}
