import React, { useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import { useTokenProvider } from 'providers/TokenProvider';
import { useAuth } from 'app/pages/authentication/auth-provider';

export default function AxiosConfig() {
  const { token } = useTokenProvider();
  const { logout } = useAuth();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (config.headers === undefined) {
          config.headers = {};
        }
        config.headers['Content-Type'] = config.headers['Content-Type']
          ? config.headers['Content-Type']
          : 'application/json';
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [token]);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status) {
        logout();
      } else {
        return Promise.reject(error);
      }
    }
  );
  return <></>;
}
