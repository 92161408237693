import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';

interface Env {
  REACT_APP_BASE_URL: string;
}

interface EnvironmentContextType {
  env: Env | null;
  setEnv: Dispatch<SetStateAction<Env | null>>;
}

const initialContext = {
  env: null,
  setEnv: () => {},
};

const EnvironmentContext =
  createContext<EnvironmentContextType>(initialContext);

const EnvironmentProvider = ({ children }) => {
  const [env, setEnv] = useState<Env | null>(initialContext.env);
  useEffect(() => {
    const newEnv = window['env'] || {
      REACT_APP_BASE_URL: 'https://api.dev.getalfi.com',
    };

    localStorage.setItem('env', JSON.stringify(newEnv));

    setEnv(newEnv);
  }, []);

  return (
    <EnvironmentContext.Provider value={{ env, setEnv }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

const useEnvironmentProvider = () => {
  const { env, setEnv } = useContext(EnvironmentContext);
  return { env, setEnv };
};

export { useEnvironmentProvider, EnvironmentProvider, EnvironmentContext };
