import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Tenant } from 'app/common/types';
interface TenantContextType {
  tenant: Tenant;
  setTenant: Dispatch<SetStateAction<Tenant>>;
}

const storageTenant = window.localStorage.getItem('tenant');

const initialContext = {
  tenant: storageTenant ? JSON.parse(storageTenant) : '',
  setTenant: () => {},
};

const TenantContext = createContext<TenantContextType>(initialContext);

const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState<Tenant>(initialContext.tenant);

  useEffect(() => {
    window.localStorage.setItem('tenant', JSON.stringify(tenant));
  }, [tenant]);

  useEffect(() => {
    //TODO fetch all tenants
    //axios.get('').then(res => setAllTenants(res.data))
  }, []);

  return (
    <TenantContext.Provider value={{ tenant, setTenant }}>
      {children}
    </TenantContext.Provider>
  );
};

const useTenantProvider = () => {
  const { tenant, setTenant } = useContext<TenantContextType>(TenantContext);
  return { tenant, setTenant };
};

export { useTenantProvider, TenantProvider };
