import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ArrowLeftIcon } from 'app/common/shared/icons/icons';
import { Input } from 'app/common/shared/input/input';
import { Text } from 'app/common/shared/text/text';
import { Footer } from '../onboarding/action-footer';
import { useEnvironmentProvider } from 'providers/EnvironmentProvider';
import { PhoneInput } from 'app/common/shared/phone-input/phone-input';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordScreenValues {
  identifier?: string;
  password: string;
  maskedPhoneNumber?: string;
  phoneNumberWithCountryCode?: string;
  phoneNumberCodeId?: number;
  phoneNumber?: string;
}

export const ForgotPassword = () => {
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const navigate = useNavigate();

  const { env } = useEnvironmentProvider();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
    unregister,
  } = useForm<ForgotPasswordScreenValues>({
    mode: 'onChange',
  });

  const submit = handleSubmit(async (data) => {
    const payload = isEmailLogin
      ? {
          email: data.identifier?.trim(),
        }
      : {
          phoneNumber: data.phoneNumber,
          phoneNumberCodeId: data.phoneNumberCodeId,
        };

    try {
      const route = isEmailLogin
        ? '/nextgen/ResetPasswordByEmail'
        : '/nextgen/Users/ResetPasswordByPhoneNumber';

      const { status } = await axios.post(
        `${env?.REACT_APP_BASE_URL}${route}`,
        payload
      );

      if (status == 200) {
        const message = isEmailLogin
          ? 'Your password has been reset. Please check your email for your new password. Be sure to check your spam folder if you cannot find this email.'
          : 'Your password has been reset. Please check your phone for an SMS with your new password.';

        toast.success(message, { autoClose: 20000 });

        navigate('/login');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to reset password');
    }
  });

  useEffect(() => {
    register('phoneNumberCodeId', { required: !isEmailLogin });

    register('phoneNumber', { required: !isEmailLogin });

    return () => {
      if (!isEmailLogin) {
        unregister('phoneNumberCodeId');
        unregister('phoneNumber');
        unregister('maskedPhoneNumber');
        return;
      }

      unregister('identifier');
    };
  }, [register, isEmailLogin]);

  return (
    <div className='flex flex-col h-full relative md:mx-auto md:w-3/4 md:max-w-screen-sm'>
      <form
        className='py-8 h-full relative flex flex-col px-6'
        onSubmit={submit}
      >
        <div className='flex items-center mb-8 gap-3'>
          <ArrowLeftIcon
            className='cursor-pointer'
            onClick={() => navigate('/')}
          />
          <Text variant='h4Bold'>
            <h4 className='m-0'>Forgot Password</h4>
          </Text>
        </div>
        {isEmailLogin ? (
          <Input
            label='Enter Email Address'
            type='email'
            className='mb-6'
            {...register('identifier', { required: isEmailLogin })}
          />
        ) : (
          <div className='mb-6 flex flex-col'>
            <PhoneInput
              label='Enter Phone Number'
              inputProps={{
                autoFocus: true,
                type: 'tel',
                ...register('maskedPhoneNumber', {
                  required: !isEmailLogin,
                  validate: () => isPhoneValid,
                }),
                value: watch('maskedPhoneNumber'),
              }}
              onCountryCodeChange={() => {
                setValue('maskedPhoneNumber', '');
              }}
              onAccept={({ maskedValue, selectedValue, unmaskedValue }) => {
                const shouldValidateMask = !!selectedValue.format;

                setIsPhoneValid(
                  shouldValidateMask
                    ? `${maskedValue.replace('_', '')}` === maskedValue
                    : true
                );

                setValue('phoneNumberCodeId', selectedValue.id, {
                  shouldValidate: true,
                });

                setValue('maskedPhoneNumber', maskedValue, {
                  shouldValidate: true,
                });

                setValue('phoneNumber', unmaskedValue, {
                  shouldValidate: true,
                });
              }}
            />
          </div>
        )}
        <button
          type='button'
          className='m-0 text-primary text-center cursor-pointer bg-transparent border-0 self-center'
          onClick={() => setIsEmailLogin(!isEmailLogin)}
        >
          <Text variant='h5SemiBold'>
            <span>Use {isEmailLogin ? 'phone number' : 'email'} instead</span>
          </Text>
        </button>
        <Footer disabled={!isValid} />
      </form>
    </div>
  );
};
